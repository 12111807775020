const formatCurrency = (number, currency = 'NGN') => {
    let currency_symbol = currency === 'NGN' ? '₦' : (currency === 'USD' || currency === 'CAD' ? '$' : (currency === 'GBP' ? '£' : ''))
    if(isNaN(number)) {
        number = 0
    }
    return { 
        with_currency: currency_symbol+new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number), 
        formatted: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number), 
        raw: number
    }
    // return { with_currency: '₦'+new Intl.NumberFormat().format(number), formatted: new Intl.NumberFormat().format(number), raw: number}
}

export default formatCurrency