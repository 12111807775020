
import axiosInstance from '../utils/axios';

export default class ServiceHelper {
    static async handleGetRequest(url) {
        try {
            const res = await axiosInstance.get(url);
            return res.data;
        } catch (error) {
            throw error
        }
    }

    static async handlePostRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.post(url, payload, headers);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    static async handlePatchRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.patch(url, payload, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
    static async handlePutRequest(url, payload, headers = null) {
        try {
            const res = await axiosInstance.put(url, payload, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
    static async handleDeleteRequest(url, headers = null) {
        try {
            const res = await axiosInstance.delete(url, headers)
            return res.data;
        } catch (error) {
            throw error;
        }
    }
    static async handleDownloadRequest(url) {
        try {
            const res = await axiosInstance.get(url, {
                responseType: 'blob',
            })
            let blob = new Blob([res.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }),
              downloadUrl = window.URL.createObjectURL(blob);
            window.open(downloadUrl);
            return res.data;
        } catch (error) {
            throw error;
        }
    }
}