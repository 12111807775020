import { Row, Col, Container, Card, Button, Breadcrumb } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useDispatch } from "react-redux";
import { useState } from "react";
import rectangle from '../../assets/images/rectangle.svg'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Plans = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})


    const plans = [
        {
            id: 1,
            name: 'Classic Plan',
            amount: '₦0.00',
            benefits: [
                {
                    id: 1,
                    status: 'active',
                    name: '0.25% per transaction capped at N2,000.00',
                    terms: false,
                    paid:false,
                },
                {
                    id: 2,
                    status: 'active',
                    name: 'Single withdrawal limit- N3million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 3,
                    status: 'active',
                    name: 'Daily withdrawal limit N20million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 4,
                    status: 'active',
                    name: 'Accepts international payments',
                    terms: false,
                    paid:false,
                },
                {
                    id: 5,
                    status: 'active',
                    name: 'Generate Paycode gift voucher',
                    terms: false,
                    paid:false,
                },
                {
                    id: 6,
                    status: 'active',
                    name: 'P2P for Suppliers/Vendor',
                    terms: false,
                    paid:false,
                },
                {
                    id: 7,
                    status: 'active',
                    name: 'Add maximum of 4 managers for alerts',
                    terms: false,
                    paid:false,
                },
                {
                    id: 8,
                    status: 'active',
                    name: 'Create 1-business bank account',
                    terms: true,
                    paid: false
                },
                {
                    id: 9,
                    status: 'active',
                    name: 'Instant Withdrawal',
                    terms: false,
                    paid: false
                },
                {
                    id: 10,
                    status: 'active',
                    name: 'Accept Paycode from customer',
                    terms: false,
                    paid: false
                },
                {
                    id: 11,
                    status: 'active',
                    name: 'CAC business registration',
                    terms: false,
                    paid: true
                },
                {
                    id: 12,
                    status: 'active',
                    name: '12/5 Customer support',
                    terms: false,
                    paid: false
                },
            ],
        },
        {
            id: 2,
            name: 'Premium Plan',
            amount: '₦8,300',
            benefits: [
                {
                    id: 1,
                    status: 'active',
                    name: '0.25% per transaction capped at N2,000.00',
                    terms: false,
                    paid:false,
                },
                {
                    id: 2,
                    status: 'active',
                    name: 'Single withdrawal limit- N3million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 3,
                    status: 'active',
                    name: 'Daily withdrawal limit N20million',
                    terms: false,
                    paid:false,
                },
                {
                    id: 4,
                    status: 'active',
                    name: 'Accepts international payments',
                    terms: false,
                    paid:false,
                },
                {
                    id: 5,
                    status: 'active',
                    name: 'Generate Paycode gift voucher',
                    terms: false,
                    paid:false,
                },
                {
                    id: 6,
                    status: 'active',
                    name: 'P2P for Suppliers/Vendor',
                    terms: false,
                    paid:false,
                },
                {
                    id: 7,
                    status: 'active',
                    name: 'Add maximum of 4 managers for alerts',
                    terms: false,
                    paid:false,
                },
                {
                    id: 8,
                    status: 'active',
                    name: 'Create 2-business bank account',
                    terms: true,
                    paid: false
                },
                {
                    id: 9,
                    status: 'active',
                    name: 'Next day withdrawal',
                    terms: false,
                    paid: false
                },
                {
                    id: 10,
                    status: 'active',
                    name: 'Accept Paycode from customer',
                    terms: false,
                    paid: false
                },
                {
                    id: 11,
                    status: 'active',
                    name: 'Generate unlimited sales report',
                    terms: false,
                    paid: false
                },
                {
                    id: 12,
                    status: 'active',
                    name: 'CRM/Marketing Tool',
                    terms: false,
                    paid: false
                },
                {
                    id: 13,
                    status: 'active',
                    name: 'Invoicing Tool',
                    terms: false,
                    paid: false
                },
                {
                    id: 14,
                    status: 'active',
                    name: 'Personalized customer support',
                    terms: false,
                    paid: false
                },
                {
                    id: 15,
                    status: 'active',
                    name: 'Loan (Optional)',
                    terms: false,
                    paid: false
                },
            ],
        },
    ];

    const subscribeNow = (plan) => {
        Swal.fire({
            title: `${plan.name} subscription`,
            text: `${plan.amount} will be charged from your main wallet. Do you wish to continue?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Proceed"
        })
        .then((result) => {
            if (result.isConfirmed) {
                // dispatch()
                Swal.fire({
                    icon: "success",
                    title: "Successfully Subscribed! This is test though",
                })
                .then(() => {
                    navigate('/subscriptions/plans')
                })
            }
        })
    }


    return (
        <Layout title="Subscription">
            <Container className="mt-3 px-4 mb-4">
                {/* <Breadcrumb>
                    <Breadcrumb.Item href="#">Wallet</Breadcrumb.Item>
                    <Breadcrumb.Item >My subscription</Breadcrumb.Item>
                    <Breadcrumb.Item active>Plan</Breadcrumb.Item>
                </Breadcrumb> */}
                {
                    plans.length ? (
                        <Row>
                            {
                                plans.map((plan, index) => ( 
                                        <Col md={5} key={index}>
                                            <Card className="border-0 shadow-sm rounded-16">
                                                <Card.Body className="py-4 pe-3 ps-3 pricing">
                                                    <h4 className="text-secondary">{plan.name}</h4>
                                                    <div className="mb-3 mt-3">
                                                        <h1 className="text-bold font-bold">{plan.amount}/<small className="text-sm">month</small> </h1>
                                                    </div>
            
                                                    <div className="plan">
                                                        {/* <div className="pricing mb-3">
                                                            <p className="m-0">Other Prices:</p>
                                                            <div className="price-tags">
                                                                <h5>
                                                                    ₦40,000/<small>6months</small>, 
                                                                    ₦80,000/<small>12months</small> 
                                                                </h5>
                                                            </div>
                                                        </div> */}
                                                        <h6 className="text-primaryd">Benefits</h6>
                                                        {
                                                            plan.benefits.length ? (
                                                                <ul>
                                                                    {
                                                                        plan.benefits.map((plan, index) => (
                                                                            <li key={index}>
                                                                               <span className="me-3">
                                                                                    <img src={rectangle} alt="icon" />
                                                                                </span> 
                                                                                {plan.name} 
                                                                                    { 
                                                                                        plan.terms ?  
                                                                                            <small className="text-danger font-bold">(Please read T&C)</small> : 
                                                                                        plan.paid ?  
                                                                                            <small className="text-danger font-bold">(Paid service)</small> : null
                                                                                    }
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            ) : null
                                                        }
                                                        <Button 
                                                            type="button" 
                                                            variant="primary" 
                                                            // variant={plan.name === "Premium Plan" ? "primary" : "light"} 
                                                            disabled={plan.name === "Classic Plan"}
                                                            className={`form-control mt-5 `}
                                                            onClick={() => subscribeNow(plan)}
                                                        >
                                                            {/* className={`form-control mt-5 ${plan.name === 'Premium Plan' ? null : 'bg---primary'} `}> */}
                                                                Subscribe
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                ))
                            }
                        </Row>
                    ) : (
                        <div className="text-center col-md-12">
                            <p>No plans available</p>
                        </div>
                    )
                }
            </Container>
        </Layout>
    );
}

export default Plans;