import ServiceHelper from "./service.helper";

export default class UserService {
    static BASE_URL = '/auth';

    static async getProfile() {
        return await ServiceHelper.handleGetRequest(this.BASE_URL+"/user");
    }

    static async getStore() {
        return await ServiceHelper.handleGetRequest("/merchants/");
    }

    static createBusiness(payload) {
        return ServiceHelper.handlePostRequest("/create_business/", payload, {headers:{'Content-Type': 'multipart/form-data'}});
    }

    static updateBusinessInfo(user_id, payload) {
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/${user_id}/business`, payload)
    }

    static updateProfilePhoto(payload) {
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/profile_photo/`, payload)
    }

    static walletDetails() {
        return ServiceHelper.handleGetRequest(`/wallet_details/`)
    }

    static createCustomer(payload) {
        return ServiceHelper.handlePostRequest(`/customers_list/`, payload)
    }

    static createUser(payload) {
        return ServiceHelper.handlePostRequest(`/managers/`, payload)
    }

    static getUsers() {
        return ServiceHelper.handleGetRequest(`/managers/`)
    }

    static getCustomers() {
        return ServiceHelper.handleGetRequest(`/customers_list/`)
    }

    static getEmails() {
        return ServiceHelper.handleGetRequest(`/customer_email/`)
    }

    static getSms() {
        return ServiceHelper.handleGetRequest(`/customer_sms/`)
    }

    static createSms(payload) {
        return ServiceHelper.handlePostRequest(`/customer_sms/`, payload)
    }

    static createEmail(payload) {
        return ServiceHelper.handlePostRequest(`/customer_email/`, payload)
    }

    static deleteSms(id) {
        return ServiceHelper.handleDeleteRequest(`/delete_sms/${id}/`)
    }

    static deleteEmail(id) {
        return ServiceHelper.handleDeleteRequest(`/delete_email/${id}/`)
    }

    static deleteCustomer(customer_id) {
        return ServiceHelper.handleDeleteRequest(`/delete_customer/${customer_id}/`)
    }

    static deleteUser(user_id) {
        return ServiceHelper.handleDeleteRequest(`/manage_team/${user_id}/`)
    }

    static saveSocialMedia(payload) {
        return ServiceHelper.handlePatchRequest(`/social_media/`, payload)
    }

    static getSocialMedia() {
        return ServiceHelper.handleGetRequest(`/social_media/`)
    }

    static manageUser(payload) {
        return ServiceHelper.handlePatchRequest(`/manage_team/${payload.user_id}/`, payload)
    }

    static withdrawal(payload) {
        return ServiceHelper.handlePostRequest(`/withdrawal/`, payload)
    }

    static depositFund(payload) {
        return ServiceHelper.handlePostRequest(`/funds_deposit/`, payload)
    }
    
    static pinVerification(payload) {
        return ServiceHelper.handleGetRequest(`/verify_pin/?wallet_pin=${payload.wallet_pin}`)
    }

    static changePassword(payload) {
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/change_password/`, payload)
    }

    static accountUpgrade(payload) {
        return ServiceHelper.handlePostRequest(`/account_upgrade/`, payload)
    }

    static rewards() {
        return ServiceHelper.handleGetRequest(`/rewards/`)
    }

    static customerRating(payload) {
        return ServiceHelper.handlePostRequest(`/customer_rating/${payload.merchant_id}/`, payload)
    }

    static rating() {
        return ServiceHelper.handleGetRequest(`/ratings/`)
    }

    static hmoEligibility() {
        return ServiceHelper.handleGetRequest(`/check_hmo_eligibility/`)
    }

    static hmoPeck(payload) {
        return ServiceHelper.handlePostRequest(`/accept_hmo_perk/`, payload)
    }

    static limitRequest(payload) {
        return ServiceHelper.handlePostRequest(`/withdrawal_limit/`, payload)
    }

    static getNotifications() {
        return ServiceHelper.handleGetRequest(`/notification/?read=False`)
    }

    static readNotifications(id) {
        return ServiceHelper.handlePatchRequest(`/read_notification/${id}/`, {read: true})
    }

    static getBackendKeys(id) {
        return ServiceHelper.handleGetRequest(`/api-v1/third_party_keys/`, {headers:{
            'Authorization': `Basic ${process.env.REACT_APP_CLIENT_USER}:${process.env.REACT_APP_CLIENT_KEY}`
        }})
    }
}