import ServiceHelper from "./service.helper";

export default class BillsPaymentService {

    static async getProviders(provider) {
        switch (provider) {
            case 'airtime':
                return await ServiceHelper.handleGetRequest("/bills/airtime_purchase/");
                break;
            case 'mobile_data':
                return await ServiceHelper.handleGetRequest("/bills/data_bundle/?action=bundle_providers");
                break;
            case 'electricity':
                return await ServiceHelper.handleGetRequest("/bills/electricity_purchase/");
                break;
            case 'cable_tv':
                return await ServiceHelper.handleGetRequest("/bills/cable_tv/?action=cable_tv_providers");
                break;
            default:
                return 'error';
                break;
        }
    }

    static async getDataBundles(network) {
        return await ServiceHelper.handleGetRequest(`/bills/data_bundle/?action=data_bundles&service_type=${network}`);
    }

    static async getBouque(provider) {
        return await ServiceHelper.handleGetRequest(`/bills/cable_tv/?action=cable_provider_bouquets&service_type=${provider}`);
    }

    static async getAddOns(payload) {
        return await ServiceHelper.handleGetRequest(`/bills/cable_tv/?action=cable_provider_addons&service_type=${payload.provider}&product_code=${payload.product_code}`);
    }

    static async accountValidation(payload) {
        switch (payload.service) {
            case 'cable_tv':
                return await ServiceHelper.handleGetRequest(`/bills/cable_tv/?action=account_name_validation&service_type=${payload.provider}&account_number=${payload.card_number}`);
                break;
            case 'electricity':
                return await ServiceHelper.handlePutRequest(`/bills/electricity_purchase/`, {service_type: payload.provider, account_number: payload.meter_number});
                break;
        
            default:
                return 'error';
                break;
        }
    }

    static async makePurchase(payload) {
        switch (payload.service) {
            case 'airtime':
                return await ServiceHelper.handlePostRequest(`/bills/airtime_purchase/`, {
                    service_type: payload.provider, 
                    amount: payload.amount, 
                    phone: payload.phone_number,
                    pin: payload.pin
                });
                break;
            case 'mobile_data':
                return await ServiceHelper.handlePostRequest(`/bills/data_bundle/`, {
                    service_type: payload.provider,
                    amount: payload.amount, 
                    phone: payload.phone_number, 
                    datacode: payload.product_code,
                    pin: payload.pin
                });
                break;
            case 'electricity':
                return await ServiceHelper.handlePostRequest(`/bills/electricity_purchase/`, {
                    service_type: payload.provider, 
                    account_number: payload.meter_number, 
                    amount: payload.amount,
                    pin: payload.pin
                });
                break;
            case 'cable_tv':
                let request_data = {
                        service_type: payload.provider, 
                        smartcard_number: payload.card_number, 
                        total_amount: payload.amount,
                        product_months_paid_for: payload.product_months_paid_for,
                        product_code: payload.product_code,
                        pin: payload.pin
                    }
                    if(payload.addon_code) {
                        request_data.addon_code = payload.addon_code
                        request_data.addon_months_paid_for = payload.addon_months_paid_for
                    }
                return await ServiceHelper.handlePostRequest(`/bills/cable_tv/`, request_data);
                break;
            default:
                return 'error';
                break;
        }
    }

}