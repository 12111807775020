import { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hmoEnrollment } from "../../redux/slices/users";
import Swal from "sweetalert2";
import logo from '../../assets/images/hmo.jpeg';


const HmoPerk = (props) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [show_form, setShowForm] = useState(false)

    useEffect(() => {
        setShowForm(props.show_form)
    }, [props.show_form])

    const { user } = useSelector((state) => state.user)

    const { business } = useSelector((state) => state.user)

    const acceptHmo = () => {
        setLoading(true)
        dispatch(hmoEnrollment())
        .then((response) => {
            if(response.payload.success === true) {
                Swal.fire({
                    text: response.payload.message,
                    icon: "success"
                })
                .then(() => {
                   setShowForm(false)
                })
            }
            else if(response.payload.success === 'False') {
                Swal.fire({
                    text: response.payload.message,
                    icon: "error"
                })
            }
        })
        .finally(() => setShowForm(false))
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show_form}
            onHide={() => setShowForm(false)}
            backdrop="static"
        >
            {/* <Modal.Header closeButton className="font-bold">Hmo Perk</Modal.Header> */}
            <Modal.Body className="px-4">
                <div>
                    <div className="text-center">
                        <img src={logo} alt="doctorkonnect" width={200} />
                    </div>
                   <p>Hi <strong>{business?.store_name}</strong> </p> 

                    <p>
                        Congratuations!!!, You have successfully earned a pro-active wellness PERK for your business through our health care partner. Kindly "Accept or Decline" if you want us to send your details to our medical health partner "Doktor Connect".
                    </p>   
                    <p>
                        You will recieve a call from our health insurance partners to advise you on how you can access & claim your proactive wellness perk. 
                    </p>
                    <p>
                        This perk is valid for One Year!
                    </p>

                    <p>
                        Thank You for trusting us. Health Is Wealth!
                    </p> 
                </div>
            </Modal.Body>
            <Modal.Footer>
                    <Button 
                        variant="outline-primary" 
                        onClick={ () => setShowForm(false) }
                    >
                       Decline
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={acceptHmo}
                    >
                       I Accept <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                    </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default HmoPerk;