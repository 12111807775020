import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import SupportService from '../../services/support.service';

export const getAllTickets = createAsyncThunk(
  "/support_ticket/",
  async(thunkAPI) => {
    try {
      const response = await SupportService.getTickets();
        // console.log(response)
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const createNewTicket = createAsyncThunk(
  "/support_ticket/",
  async(id, thunkAPI) => {
    try {
      const response = await SupportService.createTicket(id);
      // console.log('BEN ', response)
      thunkAPI.dispatch(setMessage({message: response?.message, status: response?.success ? 'True' : 'False'}))
      return response;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error?.message, status: 'False'}))
      return error;
    }
  }
)


export const respondToTicket = createAsyncThunk(
  "/support_response/",
  async(payload, thunkAPI) => {
    try {
      const response = await SupportService.replyTicket(payload);
      // console.log("Ticket transfer response", response)
      thunkAPI.dispatch(setMessage({message: response?.message, status: 'True'}))
      return response;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error;
    }
  }
)

export const getResponses = createAsyncThunk(
  "/support_response/",
  async(id, thunkAPI) => {
    try {
      const response = await SupportService.getTicketReplies(id);
        // console.log("Ticket search response", response)
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const manageTicket = createAsyncThunk(
  "/merchants/?merchant_id=",
  async(id, thunkAPI) => {
    try {
      const response = await SupportService.markResolved(id);
      return response;
    } catch (error) {
      return error;
    }
  }
)

const supportSlice = createSlice({
  name: "support",
  initialState: {
    tickets: null,
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
  },
  extraReducers: {
    [getAllTickets.fulfilled]: (state, action) => {
        state.tickets = action.payload.results
    },
  }
})

const { reducer } = supportSlice
export default reducer;