import React from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';

const currencyOptions = [
  { value: 'USD', label: 'USD', countryCode: 'US' },
  { value: 'EUR', label: 'EUR', countryCode: 'EU' },
  { value: 'GBP', label: 'GBP', countryCode: 'GB' },
  { value: 'CAD', label: 'CAD', countryCode: 'CA' },
  { value: 'NGN', label: 'NGN', countryCode: 'NG' },
];

const CurrencyDropdown = ({ onChange, value }) => {

  const { currency } = useSelector((state) => state.auth)

  const formatOptionLabel = ({ value, label, countryCode }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReactCountryFlag countryCode={countryCode} svg style={{ marginRight: 10 }} />
      <span>{label}</span>
    </div>
  );
 
  return (
    <Select
      value={currencyOptions.find(option => option.value === value)}
      onChange={onChange}
      options={currencyOptions}
      defaultValue={ currencyOptions.find(option => option.value === currency) }
      formatOptionLabel={formatOptionLabel}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 38,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 4,
        }),
        clearIndicator: (base) => ({
          ...base,
          padding: 4,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0px 6px',
        }),
        input: (base) => ({
          ...base,
          margin: 0,
          padding: 0,
        }),
      }}
    />
  );
};

export default CurrencyDropdown;
