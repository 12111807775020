import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import P2PService from '../../services/p2p.service';

export const getBeneficiaries = createAsyncThunk(
  "/beneficiaries/",
  async(thunkAPI) => {
    try {
      const response = await P2PService.getBeneficiaries();
        // console.log(response.all_banks)
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const addBeneficiary = createAsyncThunk(
  "/beneficiaries/",
  async(id, thunkAPI) => {
    try {
      const response = await P2PService.addBeneficiary(id);
      // console.log('BEN ', response)
      thunkAPI.dispatch(setMessage({message: response?.message, status: response?.success ? 'True' : 'False'}))
      return response;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error?.message, status: 'False'}))
      return error;
    }
  }
)

export const removeBeneficiary = createAsyncThunk(
  "/beneficiaries/",
  async(id, thunkAPI) => {
    try {
      const response = await P2PService.removeBeneficiary(id);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const makeTransfer = createAsyncThunk(
  "/p2p_transfer/",
  async(payload, thunkAPI) => {
    try {
      const response = await P2PService.transfer(payload);
      // console.log("P2P transfer response", response)
      thunkAPI.dispatch(setMessage({message: response?.message, status: 'True'}))
      return response;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error;
    }
  }
)

export const getMerchants = createAsyncThunk(
  "/p2p_seacrh/",
  async(id, thunkAPI) => {
    try {
      const response = await P2PService.search(id);
        // console.log("P2p search response", response)
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const getMerchantById = createAsyncThunk(
  "/merchants/?merchant_id=",
  async(id, thunkAPI) => {
    try {
      const response = await P2PService.getMerchant(id);
      return response[0];
    } catch (error) {
      return error;
    }
  }
)

const p2pSlice = createSlice({
  name: "p2p",
  initialState: {
    beneficiaries: null,
    merchants: null,
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
  },
  extraReducers: {
    [getBeneficiaries.fulfilled]: (state, action) => {
        state.beneficiaries = action.payload
    },
    [getMerchants.fulfilled]: (state, action) => {
        state.merchants = action.payload
    }
  }
})

const { reducer } = p2pSlice
export default reducer;