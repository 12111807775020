import { Row, Col, Container, Card, Form, Button, Table, Badge, Spinner } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../components/table/Paginator";
import { getTransactionHistory, getTransactionReports } from "../redux/slices/account.service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import formatCurrency from "../utils/currencyFormmter";
import Swal from "sweetalert2";
import dateFormatter from "../utils/dateFormatter";



const Reports = () => {

    const dispatch = useDispatch()



    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    const [payload, setPayload] = useState({
        page: 1,
        transaction_type: '',
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        reference: '',
        gateway: '',
    })

    const [loading, setLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        loadTransactions()
    }, [payload.page])

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }


    const loadTransactions = () =>  {
        dispatch(getTransactionHistory(payload))
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
                if(response.payload.results) {
                    setResults(response.payload.results)
                }
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
    }

    const handleFilter  = (download) => {
        // console.log(payload)
        let request_payload = payload
        request_payload.download = ''
        if(download === 'True'){
            request_payload.download = download
        }
        dispatch(getTransactionReports(request_payload))
        .then((e) => {
            if(e.payload.results) {
                setResults(e.payload.results)
            }
        })
        .finally(() => {
            setLoading(false)
            setDownloading(false)
        })
    }

    const handleDownload = () => {
        Swal.fire({
            title: 'Download  Transaction Report?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if(result.isConfirmed) {
                setPayload((prevState)=> ({...prevState, ['download']: 'True'}))
                handleFilter('True')
            }
            else {
                setPayload((prevState)=> ({...prevState, ['download']: 'False'}))
            }
        })
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    return (
        <Layout>
            <Container className="mt-4 px-4">
                <Col md={12}>
                    <h3>All Reports</h3>
                    <p>Kindly use the filter fields to filter your result</p>
                </Col>

                <div className="d-grid gap-4">
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Form.Group as={Col} md={4} className="mb-3">
                                        <Form.Label className="mb-0 text-sm">From Date</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name="from_date"
                                            onChange={handleInput}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} className="mb-3">
                                        <Form.Label className="mb-0 text-sm">To Date</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name="to_date"
                                            onChange={handleInput}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} className="mb-3">
                                        <Form.Label className="mb-0 text-sm">Type</Form.Label>
                                        <Form.Select
                                            required
                                            name="transaction_type"
                                            onChange={handleInput}
                                        >
                                            <option value="all">Any Type</option>
                                            <option value="Withdrawal">Withdrawal</option>
                                            <option value="Bill Payment">Bill Payment</option>
                                            <option value="Paycode">Paycode Payment</option>
                                            <option value="Invoice">Invoice</option>
                                            <option value="Vouchers">Vouchers</option>
                                            <option value="Deposit">Deposit</option>
                                            <option value="Payment Link">Payment Link</option>
                                            <option value="Commission">Commission</option>
                                            <option value="P2P Transfer">P2P Transfer</option>

                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} className="mb-3">
                                        <Form.Label className="mb-0 text-sm">Status</Form.Label>
                                        <Form.Select
                                            required
                                            name="status"
                                            onChange={handleInput}
                                        >
                                            <option value="all">All</option>
                                            <option value="success">Successful</option>
                                            <option value="pending">Pending</option>
                                            <option value="failed">Failed</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4} className="mb-3 mt-4">
                                        <Button 
                                            type="button" 
                                            variant="primary"
                                            onClick={handleFilter}
                                        >
                                            Apply Filter <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Form.Group as={Col} md={7}>
                            <Form.Control
                                type="search"
                                placeholder="Search by ID"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                            <Button type="button" variant="primary" onClick={handleDownload}>
                                <FontAwesomeIcon icon="fa fa-share-nodes" style={{fontSize: '17px'}} />
                                <span className="px-2">Export sheet</span> <Spinner animation={ downloading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                        </Form.Group>
                    </Row>
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                        <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>Details</th>
                                        <th>Order</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results?.length ? (
                                            results?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                <i className="lni lni-arrow-top-right"></i>
                                                            </small>
                                                            <strong>
                                                                <p className="m-0">{item.note}</p>  
                                                                <small>{dateFormatter(item.date)}</small>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td> {item.order}  </td>
                                                    <td>{formatCurrency(item.amount, item.currency).with_currency}</td>
                                                    <td>{formatCurrency(item.amount_after, item.currency).with_currency} </td>
                                                    <td>
                                                        <p className="m-0 d-flex align-items-center gap-2">
                                                            <FontAwesomeIcon icon="fa fa-money-check"/>
                                                            {item.transaction_type}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.status === 'success' ? 'badge-active' : 
                                                                (item.status === 'failed' ? 'badge-failed' : 
                                                                (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status === 'success' ? "#008000" : 
                                                                        (item.status === 'failed' ? "#EB5757" : 
                                                                        (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Paginator
                        next={paginationData.next}
                        prev={paginationData.prev}
                        setCurrentPage={ handlePagination }
                    />
                </div>
            </Container>
        </Layout>
    );
}

export default Reports;