import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import InventoryService from "../../services/inventory.service";


export const loginCustomer = createAsyncThunk(
    "/admin/sign-in",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.login(payload);
        thunkAPI.dispatch(inventorySlice.actions.setToken(response))
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const createAdmin = createAsyncThunk(
    "/admin/create-admin",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.createAdmin(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const register = createAsyncThunk(
    "/admin/register",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.signup(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const addProduct = createAsyncThunk(
    "/admin/create-product",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.addStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const updateProduct = createAsyncThunk(
    "/admin/uproduct/:id",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.updateStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getProduct = createAsyncThunk(
    "/admin/product/:id",
    async(product_id, thunkAPI) => {
      try {
        const response = await InventoryService.getStockById(product_id);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const removeProduct = createAsyncThunk(
    "/admin/product/:id",
    async(product_id, thunkAPI) => {
      try {
        const response = await InventoryService.deleteStock(product_id);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productUpload = createAsyncThunk(
    "/admin/product/import",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.importStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productImagesUpload = createAsyncThunk(
    "/admin/product/import",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.uploadProductImage(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getProducts = createAsyncThunk(
    "/admin/all-products",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.getAllStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productFilter = createAsyncThunk(
    "/admin/filter-products",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.filterStock();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getAnalytics = createAsyncThunk(
    "/admin/analytics",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.stockAnalytics();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const restock = createAsyncThunk(
    "/admin/create-admin",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.restockRequest(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getStockRequests = createAsyncThunk(
    "/admin/restocks",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.stockRequests();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const restockManagement = createAsyncThunk(
    "/admin/restock-manage",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.manageStockRequest(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

// VENDOR aka supplier
export const createSupplier = createAsyncThunk(
    "/admin/create-vendor",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.createVendor(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getSuppliers = createAsyncThunk(
    "/admin/all-vendor",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.getVendors(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const setupInventory = createAsyncThunk(
    "/wallx-onboarding",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.wallxOnaboading(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

const inventorySlice = createSlice({
    name: "inventory",
    initialState: {
      invLogin: false,
      products: null,
      loading: false,
    },
    reducers: {
      startLoading(state) {
        state.loading = true
      },
      setUser(state, action) {
        state.loading = false
        state.user = action.payload
      },
      setToken(state, action) {
        localStorage.setItem("invhash", action.payload.data?.access_token??''); 
        state.invLogin = action.payload.data?.access_token ? true : false
      },
      signOut(state) {
        state.invLogin = false
        state.invhash = null
      },
    },
    extraReducers: {
      [getProducts.fulfilled]: (state, action) => {
        state.products = action.payload.data
      },
      [getAnalytics.fulfilled]: (state, action) => {
        state.analytics = action.payload.data
      },
    }
  })
  
  const { reducer } = inventorySlice
  export default reducer;