import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Spinner, Modal, Form } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../../components/table/Paginator";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../../utils/currencyFormmter";
import { withdrawalRequest } from "../../../redux/slices/users";
import dateFormatter from "../../../utils/dateFormatter";
import InventoryFilter from "../../../components/InventoryFilter";
import { useNavigate } from "react-router-dom";
import { getStockRequests, restockManagement } from "../../../redux/slices/inventory.slice";

const StockItemRequest = () => {

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [showModal, setShowModal] = useState(false)
    const [payload, setPayload] = useState({
        to_currency: 'NGN', 
        currency: 'USD', 
        amount: 1,
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: 'FasePay',
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [pinValue, setpinValues] = useState(['', '', '', ''])
    const [analytics, setAnalytics] = useState({})
    const [results, setResults] = useState({})
    const [stockRequests, setStockRequests] = useState([])
    const [stockRequest, setStockRequest] = useState([])
    const [option, setOption] = useState('')



    useEffect(() => {
        loadStockRequests()
    }, [payload.page])

    const handleSelection = (item) => {
        setStockRequest(item)
        setShowModal(true)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            // const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
            // setPayload((prevState)=> ({...prevState,[name]:newValue}))
                // Allow only numbers and a single decimal point
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));

            setPayload((prevState) => ({ ...prevState, [name]: newValue }));
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
        // else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const loadStockRequests = () =>  {
        // dispatch(getFasepayTransactionHistory(payload))
        setLoading(true)
        dispatch(getStockRequests(payload))
        .then((response) => {
            if(response.payload.data) {
                setResults(response.payload.data)
                setStockRequests(response.payload.data?.data)
            }
            if(response.payload.analytics) {
                setAnalytics(response.payload?.analytics)
            }

        })
        .finally(() => setLoading(false))
    }

    const handleCurrencySelect = (event, name) => {
        setPayload({...payload, [name]: event.value})
    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    const handleSearch = () => {
        loadStockRequests()
    }

    const manageStockRequest = (id, option)  => {
        setOption(option)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Proceed!',
            cancelButtonText: 'No, Cancel',
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                dispatch(restockManagement({reference: id,  decision: option}))
                .then((response) => {
                    if(response.payload?.status === true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.payload?.message
                        })
                        .then(() => {
                            setShowModal(false)
                            loadStockRequests()
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <p>
                            <h3 className="m-0">Stock Request</h3>
                            <small>Manage stock level and restock</small>
                        </p>                        
                    </div>
                    <div className="col-md-8 text-right">
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3"
                            onClick={ () => navigate('/inventory')}
                        >
                            <FontAwesomeIcon icon={'shop'}/>
                            <span className="ps-2">Inventory</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 ms-2"
                            onClick={ () => navigate('/stock-request')}
                        >
                            <FontAwesomeIcon icon={'box'} />
                            <span className="ps-2">Stock Request</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    <Row className="data-card mb-4">
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Request</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{formatCurrency(analytics.total_request).formatted}</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Overall total
                                    </span>
                                </Card.Body>
                            </Card> 
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Approved</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(analytics?.total_approved).formatted }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                       Approved
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Pending</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(analytics?.total_pending).formatted }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Pending requests
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Inventory</h5>
                        </div>
                        <div className="">
                            <InventoryFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Stock Balance</th>
                                            <th>Quantity Requested</th>
                                            <th>Buying Price(₦)</th>
                                            <th>Selling Price(₦) <a href="#" className="text-primary"><small>Price list</small></a> </th>
                                            <th>Request Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            stockRequests?.length ? (
                                                stockRequests?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <button type="button" className="btn-plain text-primary font-bold" onClick={() => handleSelection(item)}>
                                                                {item.product?.sku}
                                                            </button>
                                                        </td>
                                                        <td>{item.product?.name}</td>
                                                        <td> {formatCurrency(item.product?.quantity).formatted}  </td>
                                                        <td>{formatCurrency(item.product?.stock_level).formatted}</td>
                                                        <td>{formatCurrency(item.quanity).formatted} </td>
                                                        <td>{formatCurrency(item.product?.price).with_currency} </td>
                                                        <td>{formatCurrency(item.product?.sales_price).with_currency} </td>
                                                        <td>{dateFormatter(item.created_at)}</td>
                                                        <td>
                                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                                                <Badge 
                                                                    className={item.status === 'approve' ? 'badge-active' : 
                                                                    (item.status === 'declined' ? 'badge-declined' : 
                                                                    (item.status === 'pending' ? 'badge-pending' : 'badge-pending'))}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <circle cx="5" cy="5" r="5" fill={
                                                                            item.status === 'approve' ? "#008000" : 
                                                                            (item.status === 'declined' ? "#EB5757" : 
                                                                            (item.status === 'pending' ? "#FAB941" : "#828282"))
                                                                        }
                                                                        />
                                                                    </svg>
                                                                    <span className="ps-1">
                                                                        {item.status === 'approve' ? 'Approved' : (item.status === 'declined' ? 'Declined' : item.status)}
                                                                    </span>
                                                                </Badge> 
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => handleSelection(item)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        stockRequests?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <Modal 
                    show={showModal} 
                    onHide={() => setShowModal(false)}
                    size="lg"
                >
                    <Modal.Header closeButton className="bg-primary text-white p-2">
                        <Modal.Title>{stockRequest.product?.sku} - {stockRequest.product?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3 justify-content-center">
                            <Col md={12} className="text-center mb-3 mt-3">
                                <img src={stockRequest.product?.image} className="img-fluid" alt="Product details" />
                                <div className="text-center">
                                    {/* <p className="font-bold">Request By: {stockRequest.user?.first_name+' '+stockRequest.user?.last_name} <br /> {dateFormatter(stockRequest.created_at)}</p> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3 product-details">
                            <Col md={6}>
                                <h5>Product Details</h5>
                                <ul>
                                    <li>Product Name: {stockRequest.product?.name}</li>
                                    <li>Product Quantity: {stockRequest.product?.quantity}</li>
                                    <li>Current Stock: {stockRequest.product?.stock_level}</li>
                                    <li>Unit Price: {stockRequest.product?.price}</li>
                                    <li>Sales Price: {stockRequest.product?.sales_price}</li>
                                    <li>Restock Level: {stockRequest.product?.restock_level}</li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <h5>Request Details</h5>
                                <ul>
                                    <li>Requested Quantity: {stockRequest.quanity}</li>
                                    <li>Request Date: {dateFormatter(stockRequest.created_at)}</li>
                                    <li>Request Status: <strong>{stockRequest.status === 'approve' ? 'Approved' : (stockRequest.status === 'declined' ? 'Declined' : stockRequest.status)}</strong> </li>
                                    <li>Request By: <strong>{stockRequest.user?.first_name+' '+stockRequest.user?.last_name}</strong>
                                    </li>
                                </ul>
                            </Col>
                            {/* {
                                stockRequest.status === 'pending' && (
                                    <Col md={12} className="mb-2 text-right px-4">
                                        <Button 
                                            type="button" 
                                            size="sm" 
                                            variant="danger"
                                            className="text-white"
                                            onClick={() => manageStockRequest(stockRequest.id, 'declined')}
                                        >
                                            Decline Request <Spinner animation={ loading && option === 'decline' ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                        <Button 
                                            type="button" 
                                            size="sm" 
                                            variant="primary"
                                            className="ms-2"
                                            onClick={() => manageStockRequest(stockRequest.id, 'approve')}
                                        >
                                            Approve <Spinner animation={ loading  && option === 'approve' ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Col>
                                )
                            } */}
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default StockItemRequest;