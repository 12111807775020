import { Row, Col, Container, Card, Button, Table, Form, Nav, InputGroup, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import AlertModal from "../../components/modal/AlertModal";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react"; 
import bank from '../../assets/images/gtb.svg';
import wema from '../../assets/images/wema.svg';
import sterling from '../../assets/images/sterling.svg';
import monniepoint from '../../assets/images/sterling.svg';
import card_logo from '../../assets/images/card.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Swal from "sweetalert2";
import { changeAccountPassword, getProfile, getSocialHandles, socialMediaUpdate, uploadProfilePhoto } from "../../redux/slices/users";
import { useNavigate } from "react-router-dom";
import { changePinConfirmation, changePinRequest, logout } from "../../redux/slices/auth";
import fb from '../../assets/images/fb.png';
import twitter from '../../assets/images/x.webp';
import instagram from '../../assets/images/insta.png';
import linkedin from '../../assets/images/linkedin.png';
import portrait from '../../assets/images/portrait.svg';
import landscape from '../../assets/images/landscape.svg';
import toast from "react-hot-toast";
import dateFormatter from "../../utils/dateFormatter";

const Settings = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { message } = useSelector((state) => state.message)
    const { accounts, business, user } = useSelector((state) => state.user)

    const [withdrawal, showForm] = useState(false)
    const [success, setSuccessRespone] = useState(false)
    const [payload, setPayload] = useState({})
    const [isActive, setActive] = useState(1)
    const [active_tab, setActiveTab] = useState(null)
    const [show_password, setShowPassword] = useState(false)
    const [security_option, setSecOption] = useState('password')
    const [loading, setLoading] = useState(false)
    const [profile_photo, setProfilePhoto] = useState(null)
    const [social_details, setSocials] = useState({})
    const [copiedText, setCopiedText] = useState()


    const banks = {
        'wema-bank': wema,
        'sterling-bank': sterling,
        'gt-bank': bank,
        'monnie-point': monniepoint
    }

    useEffect(() => {
        loadProfile()
        loadSocialDetails()
    }, [0])

    const loadProfile = () => {
        dispatch(getProfile())
        // .then((r) => console.log(r))
    }

    const loadSocialDetails = () => {
        dispatch(getSocialHandles())
        .then((r) => {
            if(r.payload?.data) {
                setSocials(r.payload?.data)
            }
        })
    }

    const manageForm = () => {
        showForm(false)
        setSuccessRespone(true)
    }

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({ ...payload, [name]:value })
    }

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setProfilePhoto(file);
        };
    }

    const saveSocialDetails = () => {
        setLoading(true)
        dispatch(socialMediaUpdate(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                loadSocialDetails()
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const changePinReq = () => {
        setLoading(true)
        dispatch(changePinRequest())
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const changePin = () => {
        setLoading(true)
        dispatch(changePinConfirmation(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                .then(() => window.location.reload())
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const changePassword = () => {
        setLoading(true)
        dispatch(changeAccountPassword(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: 'Password changed successfully. Please login to continue.'
                })
                dispatch(logout())
                navigate("/managers/login")
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const changeProfilePicture = () => {
        let request_payload = new FormData()
        request_payload.append('profile_picture', profile_photo)

        setLoading(true)
        dispatch(uploadProfilePhoto(request_payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message
                })
                loadProfile()
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const actionConfirmation = (method) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#443792',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(method === 'pin') {
                    changePin()
                }
                else if(method === 'password') {
                    changePassword()
                }
                else if(method === 'social_media') {
                    saveSocialDetails()
                }
            }
        })
    }

    const confirmProfilePhotoChange = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Your profile photo will be changed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#443792',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed'
        })
        .then((result) => {
            if (result.isConfirmed) {
                changeProfilePicture()
            }
        })
    }


    const setSecurityOption = (option) => {
        setSecOption(option)
        if(option === 'pin') {
            changePinReq()
        }
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
        .then(() => {
            setCopiedText(copyText);
            toast.success(`Copied!`, {  
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,                            
              onClose: () => setCopiedText(false),
            });
        })
    };

    return (
        <Layout>
            <Container className="mt-3 px-4 mb-4">
                <Row>
                    {
                        isActive === 1 ?
                            <Col md={3} className="bg-white full-height no-print">
                                <div className="p-4 py-3">
                                    <h4>All Settings</h4>
                                    <div className="mt-5 mb-3 sidebar-mini">
                                        <Nav>
                                            <ul className="p-0">
                                                {
                                                    active_tab !== null ? (
                                                        <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                            <button
                                                                type="button" 
                                                                size="sm" 
                                                                variant="light" 
                                                                className={active_tab === null ? "nav-link px-2 active" : "nav-link px-2"} 
                                                                style={{ color: "#828282" }}
                                                                onClick={ () => setActiveTab(null) }
                                                            >
                                                                <FontAwesomeIcon icon={'signs-post'} />
                                                                <span className="ms-2">Plaque</span>  
                                                            </button>
                                                        </li>
                                                    ) : null
                                                }
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "profile" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('profile') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M7 19C7.47273 16.7178 9.53167 15 12 15C14.4683 15 16.5273 16.7178 17 19M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className="ms-2">My Profile</span>  
                                                    </button>
                                                </li>
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "security" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('security') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M19.5 17.0039V16C19.5 14.8954 18.6046 14 17.5 14C16.3954 14 15.5 14.8954 15.5 16V17.0039M12 14.0709C11.6734 14.0242 11.3395 14 11 14C7.13401 14 4 17.134 4 21H10M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7ZM15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.0399 21 17.7599 20.891 17.546C20.7951 17.3578 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H15.6C15.0399 17 14.7599 17 14.546 17.109C14.3578 17.2049 14.2049 17.3578 14.109 17.546C14 17.7599 14 18.0399 14 18.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className="ms-2">Security Settings</span> 
                                                    </button>
                                                </li>
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "notification" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('notification') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0002 3C13.0002 2.44772 12.5525 2 12.0002 2C11.4479 2 11.0002 2.44772 11.0002 3V3.75H10.4428C8.21769 3.75 6.3761 5.48001 6.2372 7.70074L6.0162 11.2342C5.93194 12.5814 5.47965 13.8797 4.70859 14.9876C4.01191 15.9886 4.63213 17.3712 5.84306 17.5165L9.25018 17.9254V19C9.25018 20.5188 10.4814 21.75 12.0002 21.75C13.519 21.75 14.7502 20.5188 14.7502 19V17.9254L18.1573 17.5165C19.3682 17.3712 19.9885 15.9886 19.2918 14.9876C18.5207 13.8797 18.0684 12.5814 17.9842 11.2342L17.7632 7.70074C17.6243 5.48001 15.7827 3.75 13.5576 3.75H13.0002V3ZM10.4428 5.25C9.00976 5.25 7.82373 6.36417 7.73427 7.79438L7.51327 11.3278C7.41188 12.949 6.86762 14.5112 5.93977 15.8444C5.88942 15.9168 5.93425 16.0167 6.02177 16.0272L9.75943 16.4757C11.2479 16.6543 12.7524 16.6543 14.2409 16.4757L17.9786 16.0272C18.0661 16.0167 18.1109 15.9168 18.0606 15.8444C17.1327 14.5112 16.5885 12.949 16.4871 11.3278L16.2661 7.79438C16.1766 6.36417 14.9906 5.25 13.5576 5.25H10.4428ZM12.0002 20.25C11.3098 20.25 10.7502 19.6904 10.7502 19V18.25H13.2502V19C13.2502 19.6904 12.6905 20.25 12.0002 20.25Z" fill="#828282"/>
                                                        </svg>
                                                        <span className="ms-2">Notifications</span>  
                                                    </button>
                                                </li>
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "store" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('store') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M14.9995 14V17.6C14.9995 18.4401 14.9995 18.8601 14.836 19.181C14.6922 19.4632 14.4628 19.6927 14.1805 19.8365C13.8596 20 13.4396 20 12.5995 20H7.39953C6.55945 20 6.13941 20 5.81854 19.8365C5.5363 19.6927 5.30683 19.4632 5.16302 19.181C4.99953 18.8601 4.99953 18.4401 4.99953 17.6V10M18.9995 10V20M4.99953 16H14.9995M5.5573 4.88446L3.57841 8.84223C3.38673 9.22559 3.29089 9.41727 3.31391 9.57308C3.33401 9.70914 3.40927 9.8309 3.52197 9.90973C3.65104 10 3.86534 10 4.29395 10H19.7051C20.1337 10 20.348 10 20.4771 9.90973C20.5898 9.8309 20.665 9.70914 20.6851 9.57308C20.7082 9.41727 20.6123 9.22559 20.4206 8.84223L18.4418 4.88446C18.2812 4.5634 18.201 4.40287 18.0812 4.28558C17.9753 4.18187 17.8477 4.10299 17.7076 4.05465C17.5491 4 17.3696 4 17.0107 4H6.98838C6.62942 4 6.44994 4 6.29149 4.05465C6.15136 4.10299 6.02374 4.18187 5.91784 4.28558C5.79809 4.40287 5.71783 4.5634 5.5573 4.88446Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className="ms-2">Store Details</span>   
                                                    </button>
                                                </li>
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "social_media" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('social_media') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M13 20V18C13 15.2386 10.7614 13 8 13C5.23858 13 3 15.2386 3 18V20H13ZM13 20H21V19C21 16.0545 18.7614 14 16 14C14.5867 14 13.3103 14.6255 12.4009 15.6311M11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className="ms-2">Social Media</span>  
                                                    </button>
                                                </li>
                                                <li className="nav-item d-flex align-items-center gap-3 mt-1">
                                                    <button
                                                        type="button" 
                                                        size="sm" 
                                                        variant="light" 
                                                        className={active_tab === "profile_photo" ? "nav-link px-2 active" : "nav-link px-2"} 
                                                        style={{ color: "#828282" }}
                                                        onClick={ () => setActiveTab('profile_photo') }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M13.6471 16.375L12.0958 14.9623C11.3351 14.2694 10.9547 13.923 10.5236 13.7918C10.1439 13.6762 9.73844 13.6762 9.35878 13.7918C8.92768 13.923 8.5473 14.2694 7.78652 14.9623L4.92039 17.5575M13.6471 16.375L13.963 16.0873C14.7238 15.3944 15.1042 15.048 15.5352 14.9168C15.9149 14.8012 16.3204 14.8012 16.7 14.9168C17.1311 15.048 17.5115 15.3944 18.2723 16.0873L19.4237 17.0896M13.6471 16.375L17.0469 19.4528M17 9C17 10.1046 16.1046 11 15 11C13.8954 11 13 10.1046 13 9C13 7.89543 13.8954 7 15 7C16.1046 7 17 7.89543 17 9ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        <span className="ms-2">Profile Picture</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </Nav>
                                    </div>
                                </div>
                            </Col>
                        : null
                    }
                    <Col md={ isActive === 1 ? 9 : 12 }>
                        <Container fluid className="mt-5 bg-transparent ">
                            {
                                active_tab === null ? (
                                    <div className="d-flex gap-2 no-print mx-auto w-50 bg-white rounded-20 px-4 py-1 justify-content-between">
                                        <button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 1 ? "bg---primary text-primary no-print border-0 rounded-20 form-control" : "no-print form-control bg-white text-muted border-0 rounded-20"}
                                            onClick={ () => setActive(1) }
                                        >
                                            <img src={portrait} alt="portrait" />
                                            <span className="px-2">Portrait</span>
                                        </button>
                                        <button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 2 ? "bg---primary text-primary no-print border-0 rounded-20 form-control" : "no-print form-control bg-white text-muted border-0 rounded-20"}
                                            onClick={ () => setActive(2) }
                                        >
                                            <img src={landscape} alt="landscape" />
                                            <span className="px-2">Landscape</span>
                                        </button>
                                    </div>
                                ) : null
                            }
                            {
                                active_tab === null ? (
                                    <div className="printarea">
                                        <div className="bg-light mt-4 mb-4 p-2">
                                            <Row>
                                                <Col md={4} sm={12}>
                                                    <Card className="border-0 h-50k">
                                                        <Card.Body>
                                                            <h5>SCAN HERE TO PAY</h5>
                                                            <div className="text-center">
                                                                <QRCodeSVG 
                                                                    value={`${window.location.origin}/payments/qr-pay/${business?.merchant_id}`}
                                                                    size={200}
                                                                />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <Card className="border-0 mt-3">
                                                        <Card.Body>
                                                            <img  
                                                                src={card_logo} 
                                                                alt="business_card"
                                                                className="img-fluid"
                                                            />
                                                        </Card.Body>
                                                    </Card>

                                                </Col>
                                                <Col md={8} sm={12}>
                                                    <Card className="border-0">
                                                        <Card.Body>
                                                            <h5>{business?.store_name}</h5>
                                                            {/* <h5>{user?.first_name+' '+user?.last_name}</h5> */}
                                                            <Table size="sm" className="table-color">
                                                                <tbody>
                                                                    <tr className="border-bottom">
                                                                        <td>Merchant ID</td>
                                                                        <td>
                                                                            <h6 className="text-primary">
                                                                                { business?.merchant_id }  
                                                                                <button type="button" className="btn-plain text-primary" onClick={() => handleCopy(business?.merchant_id)}>
                                                                                    <FontAwesomeIcon icon={'far fa-copy'} />
                                                                                </button>
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        accounts?.length ? (
                                                                            accounts?.map((account, index) => (
                                                                            <tr className="border-bottom" key={index}>
                                                                                <td>{account?.bank_name}</td>
                                                                                <td>
                                                                                    <h5 className="font-bold">
                                                                                        {account?.account_number} 
                                                                                        <button type="button" className="btn-plain text-primary" onClick={() => handleCopy(`${account?.account_number} ${account?.bank_name} - ${account?.account_name}`)}>
                                                                                            <FontAwesomeIcon icon={'far fa-copy'} />
                                                                                        </button>
                                                                                    </h5>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                            ) : null
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Card.Body>
                                                    </Card>
                                                    <div className="d-flex align-items-center justify-content-betweden gap-3 mt-4">
                                                        {
                                                            accounts?.length ? (
                                                                accounts?.map((account, index) => (
                                                                    <div className="rounded-16 print--bg text-white p-3 col-6 mb-2" key={index}>
                                                                        <div className="d-flex gap-4 mb-3 align-items-center">
                                                                            <div 
                                                                                className="thumbnail-image rounded-circle border border-1 bg-white d-flex align-items-center justify-content-center" 
                                                                                style={{ height: "50px", width: "50px" }}
                                                                            >
                                                                                <img  
                                                                                    src={banks[account.bank_slug] ?? 'https://via.placeholder.com/70'} 
                                                                                    alt={account.bank_slug} 
                                                                                    width={40} 
                                                                                /> 
                                                                                {/* <img  
                                                                                    src={account.bank_slug === 'wema-bank' ? wema : bank} 
                                                                                    alt={account.bank_slug} 
                                                                                    width={40} 
                                                                                />  */}
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0">{account?.account_number}</h6>
                                                                                <p className="small">{account?.account_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bg---primary-light rounded py-1 px-2 w-50 ms-auto">{account?.bank_name}</div>
                                                                    </div>
                                                                ))
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div className="bg---primary-light w-50 ms-auto text-white mt-3 rounded p-1">
                                                        <p className="text--sm m-0">Generate your <strong>PAYCODE</strong> from www.wallx.co</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="text-center no-print">
                                            <Button type="button" variant="primary" size="lg" className="px-5" onClick={ () => window.print()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                    <path d="M18.2496 11C17.5592 11 16.9996 11.5596 16.9996 12.25C16.9996 12.9403 17.5592 13.5 18.2496 13.5C18.9399 13.5 19.4996 12.9403 19.4996 12.25C19.4996 11.5596 18.9399 11 18.2496 11Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67271 8.11529L6.74957 7.88446V3.91299C6.74957 3.2697 7.23963 2.73233 7.88019 2.6732C10.9532 2.38953 14.0459 2.38953 17.119 2.6732C17.7595 2.73233 18.2496 3.2697 18.2496 3.91299V7.88445L20.3265 8.11529C21.0928 8.20047 21.725 8.75389 21.9108 9.5022C22.4766 11.781 22.4766 14.1637 21.9108 16.4425L21.7275 17.1807C21.6445 17.5152 21.3442 17.75 20.9996 17.75H18.2496V20.083C18.2496 20.705 17.7782 21.2259 17.1593 21.2877C14.0605 21.5976 10.9386 21.5976 7.83989 21.2877C7.22093 21.2259 6.74957 20.705 6.74957 20.083V17.75H3.9996C3.655 17.75 3.35474 17.5152 3.2717 17.1807L3.08841 16.4425C2.52261 14.1637 2.52261 11.781 3.08841 9.5022C3.27421 8.75389 3.9064 8.20047 4.67271 8.11529ZM16.7496 7.73954C13.921 7.5076 11.0782 7.5076 8.24957 7.73954V4.14604C11.0775 3.89859 13.9216 3.89859 16.7496 4.14604V7.73954ZM16.7496 16.5016C16.7477 16.3437 16.6465 16.2077 16.5029 16.1569C13.8393 15.9208 11.16 15.9208 8.49632 16.1569C8.35135 16.2081 8.2496 16.3462 8.2496 16.506V17L8.24957 17.0063V19.8205C11.0767 20.0867 13.9225 20.0867 16.7496 19.8205V16.5016ZM20.413 16.25H18.2496V15.917C18.2496 15.2949 17.7782 14.7741 17.1593 14.7122C17.0238 14.6987 16.8884 14.6857 16.7529 14.6734C16.6961 14.6618 16.6383 14.6528 16.5797 14.6465C13.8675 14.3543 11.1317 14.3543 8.41949 14.6465C8.36087 14.6528 8.30311 14.6618 8.24635 14.6733C8.11082 14.6857 7.97533 14.6987 7.83989 14.7122C7.22093 14.7741 6.74957 15.2949 6.74957 15.917V16.25H4.58616L4.54421 16.081C4.03735 14.0396 4.03735 11.9051 4.54421 9.86366C4.57871 9.7247 4.69611 9.62193 4.83841 9.60611L6.92074 9.37467C10.6286 8.96255 14.3706 8.96255 18.0785 9.37467L20.1608 9.60611C20.3031 9.62193 20.4205 9.7247 20.455 9.86366C20.9619 11.9051 20.9619 14.0396 20.455 16.081L20.413 16.25Z" fill="white"/>
                                                </svg>
                                                <span className="px-2">Print</span>
                                            </Button>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                active_tab === 'profile' ? (
                                    <Card className="border-0 shadow-sm mt-4">
                                        <Card.Body>
                                            <Row>
                                                <Col md={12} className="text-center mb-5">
                                                    <h5>My Profile</h5>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="modal-profile">
                                                        <ul>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">First Name</small>
                                                                        {user?.first_name} 
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Email Address</small>
                                                                        {user?.email} 
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Store State</small>
                                                                        {user?.state}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Means of ID</small>
                                                                        {business?.means_of_id}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </Col>
                                                <Col md={6} className="modal-profile">
                                                    <ul>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Last Name</small>
                                                                    <strong>{user?.last_name}</strong>
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Password</small>
                                                                    <strong>********</strong>
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Date Joined</small>
                                                                    <span className="text-sm text-primary">
                                                                        <strong>{dateFormatter(user?.date_joined)}</strong>
                                                                    </span> 
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col md={12} className="w-75">
                                                    <p className="text-sm text-muted">
                                                        You can not edit details in this section, <span className="text-primary">Click here to create a ticket for updating Profile,</span>  by doing so you will be able to edit
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                            {
                                active_tab === 'store' ? (
                                    <Card className="border-0 mt-4">
                                        <Card.Body>
                                            <Row>
                                                <Col md={12} className="text-center mb-5">
                                                    <h5>Store Details</h5>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="modal-profile">
                                                        <ul>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Business/Merchant ID</small>
                                                                        {business?.merchant_id} 
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Store Address</small>
                                                                        {business?.store_address} 
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Store State</small>
                                                                        {business?.store_address}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <p className="pt-2">
                                                                        <small className="text-muted">Means of ID</small>
                                                                        {business?.means_of_id}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </Col>
                                                <Col md={6} className="modal-profile">
                                                    <ul>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Business Name</small>
                                                                    <strong>{business?.store_name}</strong>
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Store City</small>
                                                                    <strong>{business?.store_address}</strong>
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <p className="pt-2">
                                                                    <small className="text-muted">Date Joined</small>
                                                                    <span className="text-sm text-primary">
                                                                        <strong>{dateFormatter(user?.date_joined)}</strong>
                                                                    </span> 
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                            {
                                active_tab === 'social_media' ? (
                                    <Card className="border-0 mt-4">
                                        <Card.Body>
                                            <Row className="justify-content-center">
                                                <Col md={7} lg={6} sm={12}>
                                                    <Form>
                                                        <Row>
                                                            <Col md={12} className="text-center">
                                                                <h5>Social Media</h5>
                                                            </Col>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2">
                                                                    <Form.Label>
                                                                      <img src={fb} alt="facebook" width={40} />  Facebook
                                                                    </Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="facebook"
                                                                        required
                                                                        placeholder="@peter"
                                                                        onChange={handleFormInput}
                                                                        value={social_details?.facebook || payload?.facebook}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2">
                                                                    <Form.Label>
                                                                        <img src={twitter} alt="facebook" width={40} /> Twitter
                                                                    </Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="twitter"
                                                                        required
                                                                        placeholder="@peter"
                                                                        onChange={handleFormInput}
                                                                        value={social_details?.twitter || payload?.twitter}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2">
                                                                    <Form.Label>
                                                                        <img src={linkedin} alt="facebook" width={40} />  LinkedIn
                                                                    </Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="linkedin"
                                                                        required
                                                                        placeholder="@peter"
                                                                        onChange={handleFormInput}
                                                                        value={social_details?.linkedin || payload?.linkedin}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2">
                                                                    <Form.Label>
                                                                        <img src={instagram} alt="facebook" width={40} /> Instagram
                                                                    </Form.Label>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="instagram"
                                                                        required
                                                                        placeholder="@peter"
                                                                        onChange={handleFormInput}
                                                                        value={social_details?.instagram || payload?.instagram}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2">
                                                                    <Form.Label>Website</Form.Label>
                                                                    <Form.Control 
                                                                        type="url"
                                                                        placeholder="https://example.com"
                                                                        required
                                                                        name="website"
                                                                        onChange={handleFormInput}
                                                                        value={social_details?.website || payload?.website}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md={12} ms={12} lg={12} className="mt-2">
                                                                    <Button 
                                                                        type="button" 
                                                                        variant="primary" 
                                                                        className="form-control"
                                                                        onClick={() => actionConfirmation('social_media')}
                                                                    >
                                                                        Update Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                    </Button>
                                                                </Form.Group>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                            {
                                active_tab === 'security' ? (
                                    <Card className="border-0 mt-4">
                                        <Card.Body>
                                            <Col md={12} className="text-center">
                                                <h5>Security Settings</h5>
                                            </Col>
                                            <Col className="d-flex gap-2 mx-auto w-50 bg-white rounded-20 px-4 py-1 justify-content-between border border-1 px-0">
                                                <button 
                                                    type="button" 
                                                    className={security_option === 'password' ? "button-active rounded-20 form-control" : "form-control bg-white text-muted border-0 rounded-20"}
                                                    onClick={ () => setSecurityOption('password') }
                                                >
                                                    <span className="px-2">Password</span>
                                                </button>
                                                <button 
                                                    type="button" 
                                                    className={security_option === 'pin' ? "button-active rounded-20 form-control" : "form-control bg-white text-muted border-0 rounded-20"}
                                                    onClick={ () => setSecurityOption('pin') }
                                                >
                                                    <span className="px-2">Pin</span>
                                                </button>
                                            </Col>
                                            {
                                                security_option === 'password' ? (
                                                    <Row className="justify-content-center mt-5">
                                                        <Col md={7} lg={6} sm={12}>
                                                            <Form>
                                                                <Row>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Old Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                aria-describedby="inputGroupPrepend"
                                                                                required
                                                                                name="current_password"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>New Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                required
                                                                                name="new_password"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Confirm Password</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Password"
                                                                                required
                                                                                name="password_confirmation"
                                                                                onChange={handleFormInput}
                                                                                maxLength={12}
                                                                                minLength={12}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2 mt-2">
                                                                        <Button 
                                                                            type="button" 
                                                                            variant="primary" 
                                                                            className="form-control"
                                                                            onClick={() => actionConfirmation('password')}
                                                                        >
                                                                            Update Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                            {
                                                security_option === 'pin' ? (
                                                    <Row className="justify-content-center mt-5">
                                                        <Col md={7} lg={6} sm={12}>
                                                            <Form>
                                                                <Row>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Enter OTP</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="OTP Code"
                                                                                required
                                                                                name="otp"
                                                                                onChange={handleFormInput}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>New Pin</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="New Pin"
                                                                                required
                                                                                name="pin"
                                                                                onChange={handleFormInput}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                                        <Form.Label>Confirm Pin</Form.Label>
                                                                        <InputGroup>
                                                                            <Form.Control
                                                                                type={show_password ? "text" : "password"}
                                                                                placeholder="Confirm Pin"
                                                                                required
                                                                                name="confirm_pin"
                                                                                onChange={handleFormInput}
                                                                            />
                                                                            <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                                                <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                                    <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                                                </Button>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={12} ms={12} lg={12} className="mt-2">
                                                                        <Button 
                                                                            type="button" 
                                                                            variant="primary" 
                                                                            className="form-control"
                                                                            onClick={() => actionConfirmation('pin')}
                                                                        >
                                                                            Set Pin <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                ) : null
                                            }
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                            {
                                active_tab === 'profile_photo' ? (
                                    <Card className="border-0 mt-4">
                                        <Card.Body>
                                            <Col md={12} className="text-center">Profile Picture</Col>
                                            <Row className="justify-content-center">
                                                <Col md={6} lg={6} sm={12}>
                                                    <div className="profile-photo-lg mx-auto mt-5 mb-5">
                                                        <img src={user?.profile_photo} alt={user?.first_name} className="profile-photo-lg" />
                                                        <button type="button" className="edit">
                                                            <label htmlFor="photoEdit">
                                                                <FontAwesomeIcon icon="camera" style={{fontSize: "20px"}} />
                                                            </label>
                                                            <input 
                                                                type="file" 
                                                                accept="image/*" 
                                                                name="photo" 
                                                                id="photoEdit" 
                                                                style={{ display: "none" }} 
                                                                onChange={handleFileSelect}
                                                            />
                                                        </button>
                                                    </div>
                                                    <Button 
                                                        type="button" 
                                                        variant="primary" 
                                                        className="form-control"
                                                        onClick={confirmProfilePhotoChange}
                                                        disabled={ profile_photo === null }
                                                    >
                                                        Update Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                            {
                                active_tab === 'notification' ? (
                                    <Card className="border-0 mt-4">
                                        <Card.Body>
                                            <Col md={12} className="text-center mb-5">
                                                <h5>Notifications</h5>
                                                <p className="text-sm text-muted">Set your alert preferences</p>
                                            </Col>
                                            <Form>
                                                <Row className="w-100">
                                                    <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2 d-flex justify-content-between">
                                                        <div>
                                                            <h6>WhatsApp Notifications</h6>
                                                            <p className="text-sm text-muted">Turn off/on WhatsApp notifications on every alerts</p>
                                                        </div>
                                                        <div>
                                                            <BootstrapSwitchButton 
                                                                size="sm"
                                                                onstyle="primary"
                                                                offstyle="light"
                                                                onChange={(checked) => {
                                                                    setPayload({ ...payload, ['whatsapp_notifications']:checked })
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2 d-flex justify-content-between">
                                                        <div>
                                                            <h6>Email Notifications</h6>
                                                            <p className="text-sm text-muted">Turn off/on Email notifications on every alerts</p>
                                                        </div>
                                                        <div>
                                                            <BootstrapSwitchButton 
                                                                size="sm"
                                                                onstyle="primary"
                                                                offstyle="light"
                                                                onChange={(checked) => {
                                                                    setPayload({ ...payload, ['email_notifications']:checked })
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-2 d-flex justify-content-between">
                                                        <div>
                                                            <h6>SMS Notifications</h6>
                                                            <p className="text-sm text-muted">Turn off/on SMS notifications on every alerts</p>
                                                        </div>
                                                        <div>
                                                            <BootstrapSwitchButton 
                                                                size="sm"
                                                                onstyle="primary"
                                                                offstyle="light"
                                                                onChange={(checked) => {
                                                                    setPayload({ ...payload, ['sms_notifications']:checked })
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                ) : null
                            }
                        </Container>
                    </Col>
                </Row>

                <DrewsModal
                    show={withdrawal}
                    onHide={() => showForm(false)}
                    size="md"
                    dismissable={true}
                    title="New mail"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Recipient</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Recipient"
                                    name="recipient"
                                    onChange={handleFormInput}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Subject"
                                    name="subject"
                                    onChange={handleFormInput}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <textarea
                                    required
                                    rows={4}
                                    placeholder="Message"
                                    name="message"
                                    className="form-control"
                                    onChange={handleFormInput}
                                ></textarea>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={ manageForm }
                                >
                                    Send mail
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <AlertModal
                    title="Successful!"
                    text="Message sent"
                    buttonText="Ok"
                    onHide={() => setSuccessRespone(false)}
                    onClick={() => setSuccessRespone(false)}
                    show={success}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM47.42 23.42L27.42 43.42C27.2341 43.6075 27.0129 43.7562 26.7692 43.8578C26.5254 43.9593 26.264 44.0116 26 44.0116C25.736 44.0116 25.4746 43.9593 25.2309 43.8578C24.9871 43.7562 24.7659 43.6075 24.58 43.42L14.58 33.42C14.3935 33.2335 14.2456 33.0121 14.1447 32.7685C14.0438 32.5249 13.9918 32.2637 13.9918 32C13.9918 31.4674 14.2034 30.9566 14.58 30.58C14.9566 30.2034 15.4674 29.9918 16 29.9918C16.5326 29.9918 17.0434 30.2034 17.42 30.58L26 39.18L44.58 20.58C44.9566 20.2034 45.4674 19.9918 46 19.9918C46.5326 19.9918 47.0434 20.2034 47.42 20.58C47.7966 20.9566 48.0082 21.4674 48.0082 22C48.0082 22.5326 47.7966 23.0434 47.42 23.42Z" fill="#B2B1FF"/>
                    </svg>
                    
                </AlertModal>
            </Container>
        </Layout>
    );
}

export default Settings;