import { Row, Col, Container, Card, Button, Badge, Form, InputGroup, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import avatar from '../../assets/images/avatar.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTickets, getResponses, respondToTicket } from "../../redux/slices/support.slice";
import toast from "react-hot-toast";
import dateFormatter from "../../utils/dateFormatter";
// import DrewsModal from "../../components/modal/DrewsModal";


const TicketView = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [replies, setReplies] = useState([])
    // const [ticket, setTicket] = useState({})
    const [payload, setPayload] = useState({ticket_id: id})
    const [tickets, setTickets] = useState([])

    const ticket = tickets.find((tick) => tick.ticketID === id)

    useEffect(() => {
        loadTickets()
        loadResponse(id)
    }, [payload])

    // useEffect(() => {
    //     loadResponse(id)
    //     filterTickets()
    // }, [0])

    const loadResponse = (id) => {
        dispatch(getResponses(id))
        .then((res) => {
            if(res.payload?.results) {
                setReplies(res.payload?.results)
            }
        })
    }

    const loadTickets = () => {
        dispatch(getAllTickets())
        .then((res) => {
            console.log("RESPONSE", res)
            if(res.payload?.results) {
                setTickets(res.payload?.results)
            }
        })
    }

    const handleInput = (event) => {
        const { name, value } = event.target;
        setPayload((prevState) => ({...prevState, [name]: value}))
    }

    const filterTickets = () => {
        console.log('ticket', ticket)
        // let ticket = tickets?.filter(tiket => tiket.ticketID === id)[0]
        // setTicket(ticket)
    }

    const sendReply = () => {
        setLoading(true)
        dispatch(respondToTicket(payload))
        .then((response) => {
            if(response.payload.success === true) {
                toast.success('Sent')
                setPayload((prevState) => ({...prevState, ['message']: null}))
            }
            else {
                toast.error(response.payload.message)
            }
            loadResponse(id)
        })
        .finally(() => setLoading(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4 d-grid gap-5">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <button  
                        type="button"
                        className="btn-plain bg-white p-3 rounded-circle d-flex align-items-center justify-content-center" 
                        style={{height: "50px", width: "50px"}}
                        onClick={ () => navigate('/tickets')}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </button>
                </Col>
                <Card className="border-0 shadow">
                    <Card.Header>
                        <h5>
                            <span className="pe-3">Ticket - #{id}</span>
                            <Badge className="badge badge-active m-auto p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                    <circle cx="5" cy="5.5" r="5" fill="#008000"/>
                                </svg>
                                <span className="px-1">{ticket?.status}</span>
                            </Badge>
                        </h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md="7">
                                <h4 className="text-muted mb-3">Chat </h4>
                                <Form>
                                    <div className="chat-container">
                                        <div className="comments">
                                            {
                                                replies?.length ?
                                                (
                                                    replies.map((reply, index) =>
                                                        (
                                                            <div key={index} className="d-flex">
                                                                <img 
                                                                    src={reply.photo || 'https://via.placeholder.com/50'}  
                                                                    width={50} 
                                                                    alt={reply.id} 
                                                                    className="rounded-circle" 
                                                                    style={{height: "40px", width: "40px"}}
                                                                />
                                                                <div className="ms-3">
                                                                    <h6 className="mb-0">{reply.last_replied}</h6>
                                                                    <p className="text-sm text-muted">{reply.message}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                ) : null
                                            }
                                        </div>
                                        <div className="chat-box">
                                            <div className="name-tag">A</div>
                                            <InputGroup className="mb-3">
                                                <Form.Control 
                                                    aria-label="Write your comment" 
                                                    placeholder="Write your comment" 
                                                    name="message"
                                                    onChange={handleInput}
                                                />
                                                <InputGroup.Text className="bg-white text-primary">
                                                    <i className="lni lni-paperclip"></i>
                                                </InputGroup.Text>
                                                <Button 
                                                    type="button" 
                                                    variant="primary"
                                                    onClick={sendReply}
                                                    disabled={!payload.message}
                                                >
                                                    {
                                                        loading ? (
                                                            <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                        )
                                                        : (
                                                            <FontAwesomeIcon icon="paper-plane" />
                                                        )
                                                    }
                                                </Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                            <Col md="5">
                                <div className="bg-light px-3 py-2 modal-profile rounded">
                                    <ul>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Subject</small>
                                                    <strong>{ticket?.subject}</strong>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Sender</small>
                                                    <strong>{ticket?.sender || 'Nil'}</strong>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Handled by</small>
                                                    <strong>{ticket?.desk || 'Nil'}</strong>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Issued date</small>
                                                    <strong>{ticket?.date ? dateFormatter(ticket.date) : 'Nil'}</strong>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Beneficiary</small>
                                                    <strong>{ticket?.beneficiary || 'Nil'}</strong>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <p className="pt-2">
                                                    <small className="text-muted">Attachment</small>
                                                    <strong>0</strong>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    );
}

export default TicketView;