import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));

export const signup = createAsyncThunk(
  "auth/user/",
  async ({ first_name, last_name, email, password }, thunkAPI) => {
    try {
      const payload = { first_name, last_name, email, password }
      const response = await AuthService.signup(payload);
      // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      thunkAPI.dispatch(authSlice.actions.getSignupSuccess(response.data))
      return response;
    } catch (error) { 
      return error;
    }
  }
);

export const getProfile = createAsyncThunk(
  "auth/user/",
  async(thunkAPI) => {
    try {
      const response = await UserService.getProfile();
      return response[0];
    } catch (error) {
      return error
    }
  }
)

export const verification = createAsyncThunk(
  "auth/verify/",
  async ({ email, otp }, thunkAPI) => {
    try {
      const payload = { email, otp }
      const response = await AuthService.verification(payload);
      // console.log(response)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      thunkAPI.dispatch(authSlice.actions.setVerificationStatus(response.data))
      return response.data;
    } catch (error) { 
      // console.log(error)
      const message =
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(authSlice.actions.hasError(error))
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resendOtp = createAsyncThunk(
  "auth/re_verify/",
  async ({ email }, thunkAPI) => {
    try {
      const payload = { email }
      const response = await AuthService.resendOtp(payload);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) { 
      // console.log(error)
      const message =
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(authSlice.actions.hasError(error))
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password/",
  async ({ email }, thunkAPI) => {
    try {
      const payload = { email }
      const response = await AuthService.forgotPassword(payload);
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      return response;
    } catch (error) { 
      // console.log(error)
      const message =
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(authSlice.actions.hasError(error))
      return error
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/forgot_password_validate/",
  async ({ email, password, otp }, thunkAPI) => {
    try {
      const payload = { email, password, otp }
      const response = await AuthService.resetPassowrd(payload);
      // console.log(response)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      return response;
    } catch (error) { 
      // console.log(error)
      const message =
        // (error.response &&
        //   error.response.data &&
        //   error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(authSlice.actions.hasError(error))
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login/",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = { email, password }
      const response = await AuthService.login(payload);
      thunkAPI.dispatch(authSlice.actions.setAuthStatus(response.data))
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response;
    } catch (error) {
        // console.log(error)
        const message =
        error.message ||
        error.toString();
        thunkAPI.dispatch(setMessage({message: message, status: 'False' }));
      return error;
      return thunkAPI.rejectWithValue();
    }
  }
);


export const changePinRequest = createAsyncThunk(
  "/pin_update/",
  async (thunkAPI) => {
    try {
      const response = await AuthService.requestPinChange();
      // console.log(response)
      return response;
    } catch (error) { 
      // console.log(error)
      const message =
        error.message ||
        error.toString();
        return error;
    }
  }
);

export const changePinConfirmation = createAsyncThunk(
  "/pin_update/",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.confirmPinChange(payload);
      return response ;
    } catch (error) {
        // console.log(error)
        const message =
        error.message ||
        error.toString();
      return error;
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout", 
  async (_, thunkAPI) => {
    thunkAPI.dispatch(authSlice.actions.signOut())
    return true
});

export const sidebarToggle = createAsyncThunk(
  'sidebar-toggle',
  async(_, thunkAPI) => {
    thunkAPI.dispatch(authSlice.actions.toggleSidebar())
    return true
  }
)

export const completeFasePayWidgetTour = createAsyncThunk(
  'fasepay-widget',
  async(_, thunkAPI) => {
    thunkAPI.dispatch(authSlice.actions.setFasepayWidget())
    return true
  }
)

export const changeCurrency = createAsyncThunk(
  'system',
  async(payload, thunkAPI) => {
    thunkAPI.dispatch(authSlice.actions.setCurrency(payload))
    return true
  }
)

export const singupSteps = createAsyncThunk(
  'steps',
  async(payload, thunkAPI) => {
    thunkAPI.dispatch(authSlice.actions.steStep(payload))
    return true
  }
)

const initialState =
 { 
    isLoggedIn: false, 
    user: null, 
    isLoading: false,
    isOtpVerified: false,
    isAccountCreated: false,
    error: null,
    isManager: false,
    sidebar: false,
    fasepay_widget: false,
    currency: 'NGN',
    current_step: {
      step: 1,
      payload: {},
    } 
  };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    getSignupSuccess(state, action) {
      state.user = action.payload
      state.isAccountCreated = true
      state.isOtpVerified = false
    },
    setVerificationStatus(state, action) {
      state.isOtpVerified = true
      state.isAccountCreated = true
      state.user = action.payload
      state.isLoading = false
      localStorage.setItem('tkn', action.payload.token)
    },
    setAuthStatus(state, action) {
      state.user = action.payload
      state.isAccountCreated = true
      state.isOtpVerified = true
      state.isLoggedIn = true
      state.isManager = false
      localStorage.setItem('tkn', action.payload.token)
    },
    setToken(state, action) {
      localStorage.setItem("tkn", action.payload); 
    },
    signOut(state) {
      state.isLoggedIn = false
      localStorage.removeItem("tkn")
    },
    toggleSidebar(state) {
      state.sidebar = ! state.sidebar
    },
    setFasepayWidget(state) {
      state.fasepay_widget = true
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    steStep(state, action) {
      state.current_step = action.payload;
    }

  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.isManager = false;
      localStorage.removeItem('tkn')
    },
    [getProfile.fulfilled]: (state, action) => {
      state.user = action.payload
    },
  },
});

const { reducer } = authSlice;
export default reducer;