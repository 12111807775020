import { Row, Col, Container, Card, Button, Table, Dropdown, FormCheck, Form, Collapse, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import AlertModal from "../../components/modal/AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { deleteSmsById, getCustomersList, getSmsList, sendSms } from "../../redux/slices/users";
import Swal from "sweetalert2";
import Select from 'react-select';

const Sms = () => {

    const dispatch = useDispatch()

    const [withdrawal, showForm] = useState(false)
    const [success, setSuccessRespone] = useState(false)
    const [payload, setPayload] = useState({numbers: []})
    const [isActive, setActive] = useState(1)
    const [data, setData] = useState([])
    const [page, setPage] = useState('inbox')
    const [loading, setLoading] = useState(false)
    const [sentMessages, setSentMessages] = useState([])
    const [customers, setCustomers] = useState([])

    const phone_numbers = customers.map((customer) => {
        return {label: `${customer.full_name} - ${customer.mobile}`, value: customer.mobile}
    })

    useEffect(() => {
        loadSmsData()
        loadCustomers()
    }, [0])


    const loadSmsData = () => {
        dispatch(getSmsList())
        .then((response) => {
            if(response.payload?.results) {
                let results = response.payload.results.filter(item => item.sent === true)
                let inbox = response.payload.results.filter(item => item.sent !== true)
                setData(inbox)
                setSentMessages(results)
            }
        })
    }

    const manageForm = () => {
        showForm(false)
        setSuccessRespone(true)
    }

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({...payload, [name]:value})
    }

    const sendMessage = () => {
       setLoading(true)
       dispatch(sendSms(payload))
       .then((res) => {
           if(res.payload?.success && res.payload?.success == true) {
               loadSmsData()
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message || 'Message sent'
                })
                .then(() => {
                    showForm(false)
                })
            }
            else {
               Swal.fire({
                   icon: 'error',
                   text: res.payload?.message || 'Could not send message'
               })
           }
       })
       .finally(() => setLoading(false))
    }

    const confirmDelete = (id) => {
       setLoading(true)
       dispatch(deleteSmsById(id))
       .then((res) => {
            if(res.payload?.success && res.payload?.success == true) {
                loadSmsData()
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message || 'Message deleted'
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message || 'Could not delete message'
                })
            }
        })
       .finally(() => setLoading(false))
    }

    const handleDelete = (message_id) => {
        Swal.fire({
            icon: 'warning',
            text: 'Are you sure you want to delete this?',
            showConfirmButton: true,
            confirmButtonText: "Yes, Proceed",
            showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed){
                confirmDelete(message_id);
              }
          });
    }

    const loadCustomers = () => {
        dispatch(getCustomersList())
        .then((response) => {
            if(response.payload?.results) {
                setCustomers(response.payload.results)
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: response.payload?.message
                })
            }
        })
    }

    const handleSelect = (event) => {
        let contacts = event.map((contact) => {
            return contact.value
        })
        setPayload({...payload, numbers: contacts})
    }

    return (
        <Layout>
            <>
                <Row>
                    <Col md={3} className="bg-white full-height">
                        <Container className="p-4 py-5">
                            <h3>My Messages</h3>
                            <div className="mt-3 mb-3">
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="mb-5"
                                    onClick={ () => showForm(true) }
                                >
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V12.5M10.519 13.8946L11.8338 13.6347C12.5433 13.4945 12.898 13.4244 13.2288 13.2953C13.5224 13.1807 13.8014 13.0318 14.0601 12.8516C14.3515 12.6487 14.6072 12.393 15.1185 11.8816L20.267 6.73321C20.9542 6.04596 20.9542 4.9317 20.267 4.24444C19.5797 3.55719 18.4654 3.55719 17.7782 4.24445L12.5418 9.48088C12.0626 9.96004 11.823 10.1996 11.6295 10.4712C11.4577 10.7123 11.3132 10.9717 11.1987 11.2447C11.0697 11.5522 10.9922 11.8821 10.8371 12.5417L10.519 13.8946Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> */}
                                    <FontAwesomeIcon icon={'edit'} style={{fontSize: "20px"}} />
                                    <span className="px-2">Compose Message</span>
                                </Button>
                                <nav>
                                    <ul className="p-0">
                                        <li className="nav-item d-flex align-items-center gap-1 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M3.02878 8C3.02878 8 8.33702 11.3619 11 12C11.4596 12.1101 12.5404 12.1101 13 12C15.7647 11.3375 20.9712 8 20.9712 8M7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V9.8C21 8.11984 21 7.27976 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            {/* <FontAwesomeIcon icon={'envelope'} style={{fontSize: "20px;", color: "#d2d2d2"}} /> */}
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('inbox') }
                                            >
                                                Inbox
                                            </Button>
                                        </li>
                                        <li className="nav-item d-flex align-items-center gap-1 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M3.02868 10.0001L10.225 14.8167C10.8665 15.2444 11.1872 15.4582 11.5339 15.5413C11.8403 15.6147 12.1597 15.6147 12.4661 15.5413C12.8128 15.4582 13.1335 15.2444 13.775 14.8167L20.9713 10.0001M10 9.66673L11.3846 11.0001L14.5 8.00006M9.4472 4.60325L5.2472 7.24091C4.42682 7.75612 4.01662 8.01373 3.7191 8.36196C3.45577 8.67018 3.25764 9.02857 3.13667 9.4155C3 9.85266 3 10.337 3 11.3058V15.2001C3 16.8802 3 17.7203 3.32698 18.362C3.6146 18.9265 4.07354 19.3855 4.63803 19.6731C5.27976 20.0001 6.11984 20.0001 7.8 20.0001H16.2C17.8802 20.0001 18.7202 20.0001 19.362 19.6731C19.9265 19.3855 20.3854 18.9265 20.673 18.362C21 17.7203 21 16.8802 21 15.2001V11.3058C21 10.337 21 9.85266 20.8633 9.4155C20.7424 9.02857 20.5442 8.67018 20.2809 8.36196C19.9834 8.01373 19.5732 7.75612 18.7528 7.24091L14.5528 4.60325C13.6269 4.02181 13.164 3.73108 12.6673 3.61776C12.2281 3.51755 11.7719 3.51755 11.3327 3.61776C10.836 3.73108 10.3731 4.02181 9.4472 4.60325Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('sent') }
                                            >
                                                Sent Messages
                                            </Button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Container>
                    </Col>
                    <Col md={9}>
                        <Container fluid className="mt-5">
                            <Collapse in={page === 'inbox'}>
                                <div>
                                    <div className="d-flex gap-2">
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 1 ? "bg-white text-primary border-0" : "text-muted border-0"}
                                            onClick={ () => setActive(1) }
                                        >
                                            Broadcast mails
                                        </Button>
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 2 ? "bg-white text-primary border-0" : "text-muted border-0"}
                                            onClick={ () => setActive(2) }
                                        >
                                            Personalized mails
                                        </Button>
                                    </div>
                                    <Card className="border-0 bg-transparent">
                                        <Card.Body className="p-4m mailing-list bg-transparent table-responsive-lg">
                                            <Table size="sm" className="table-color">
                                                <tbody>
                                                    {
                                                        data?.length ? (
                                                            data?.map((item, index) => (
                                                                <tr key={index} className="border-bottom">
                                                                    <td className="bg-transparent">
                                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                                            <FormCheck type="checkbox"/>
                                                                            <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                            {/* <strong>
                                                                                {item.name}
                                                                            </strong> */}
                                                                        </div>
                                                                    </td>
                                                                    <td className="bg-transparent">
                                                                        {/* <span>{item.title}</span> -  */}
                                                                        <strong>{item.message}</strong>
                                                                    </td>
                                                                    <td className="bg-transparent">
                                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                                            {item.date}
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                                </Dropdown.Toggle>
    
                                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                    {/* <Dropdown.Item 
                                                                                        eventKey={1} 
                                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                                        onClick={ () => showForm(true) }
                                                                                    >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg> 
                                                                                    View Mail
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Divider /> */}
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="d-flex align-items-center gap-1 text-danger"
                                                                                        onClick={ () => handleDelete(item.id) }
                                                                                    >
                                                                                        <FontAwesomeIcon icon="trash" />
                                                                                        Delete
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Collapse>
                            <Collapse in={page === 'sent'}>
                                <Card className="border-0 bg-transparent">
                                    <Card.Body className="mailing-list bg-transparent table-responsive-lg">
                                        <h5>Sent Messages</h5>
                                        <Table size="sm" className="table-color">
                                            <tbody>
                                                {
                                                    sentMessages?.length ? (
                                                        sentMessages?.map((item, index) => (
                                                            <tr key={index} className="border-bottom">
                                                                <td className="bg-transparent">
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <FormCheck type="checkbox"/>
                                                                        <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                        <strong>
                                                                            {item.name}
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <span>{item.title}</span> - <strong>{item.message}</strong>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <div className="d-flex m-0 align-items-center justify-content-between">
                                                                        {item.date}
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                {/* <Dropdown.Item 
                                                                                    eventKey={1} 
                                                                                    className="text-primary d-flex align-items-center gap-1" 
                                                                                    onClick={ () => showForm(true) }
                                                                                >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg> 
                                                                                View Mail
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider /> */}
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-danger"
                                                                                    onClick={ () => handleDelete(item.id) }
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" /> Delete
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Collapse>
                        </Container>
                    </Col>
                </Row>

                <DrewsModal
                    show={withdrawal}
                    onHide={() => showForm(false)}
                    size="md"
                    dismissable={true}
                    title="New Sms"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Type</Form.Label>
                                <Form.Select
                                    required
                                    name="type"
                                    className="form-control"
                                    onChange={handleFormInput}
                                >
                                    <option value="">---Select---</option>
                                    <option value="personalized">Personalized</option>
                                    <option value="broadcast">Broadcast</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Select Customers</Form.Label>
                                <Select 
                                    name="numbers" 
                                    options={phone_numbers}
                                    onChange={ handleSelect }
                                    isMulti
                                    closeMenuOnSelect={false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: "35px",
                                            paddingLeft: "0px"
                                        }), 
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <textarea
                                    required
                                    rows={4}
                                    placeholder="Message"
                                    name="message"
                                    className="form-control"
                                    onChange={handleFormInput}
                                ></textarea>
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={ sendMessage }
                                >
                                    Send Message <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <AlertModal
                    title="Successful!"
                    text="Message sent"
                    buttonText="Ok"
                    onHide={() => setSuccessRespone(false)}
                    onClick={() => setSuccessRespone(false)}
                    show={success}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM47.42 23.42L27.42 43.42C27.2341 43.6075 27.0129 43.7562 26.7692 43.8578C26.5254 43.9593 26.264 44.0116 26 44.0116C25.736 44.0116 25.4746 43.9593 25.2309 43.8578C24.9871 43.7562 24.7659 43.6075 24.58 43.42L14.58 33.42C14.3935 33.2335 14.2456 33.0121 14.1447 32.7685C14.0438 32.5249 13.9918 32.2637 13.9918 32C13.9918 31.4674 14.2034 30.9566 14.58 30.58C14.9566 30.2034 15.4674 29.9918 16 29.9918C16.5326 29.9918 17.0434 30.2034 17.42 30.58L26 39.18L44.58 20.58C44.9566 20.2034 45.4674 19.9918 46 19.9918C46.5326 19.9918 47.0434 20.2034 47.42 20.58C47.7966 20.9566 48.0082 21.4674 48.0082 22C48.0082 22.5326 47.7966 23.0434 47.42 23.42Z" fill="#B2B1FF"/>
                    </svg>
                    
                </AlertModal>
            </>
        </Layout>
    );
}

export default Sms;