import AppTopBar from "./AppTopBar";
// import Navigation from "./AppTopBar";
import AppSidebar from "./Sidebar";

const Layout = ({children}) => {
    return (
        <>
            <div className="main-layout">
                <AppSidebar />
                <div className="dashboard">
                    <AppTopBar/>
                    {children}
                </div>
            </div>
        </>
    );
}

export default Layout;