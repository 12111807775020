import { Row, Col, Container, Card, Table, Form } from "react-bootstrap";
import Layout from "../../components/Layout";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Paginator from "../../components/table/Paginator";
// import avatar from '../../assets/images/avatar.svg';
import { useEffect, useState } from "react";
import Rating from "../../components/rating/Rating";
import RatingStar from "../../components/rating/RatingStar";
import { useDispatch } from "react-redux";
import { getRatings } from "../../redux/slices/users";


const Ratings = () => {

    const dispatch = useDispatch()

    const [reviews, setReviews] = useState([])
    const [rating, setRating] = useState({})

    useEffect(() => {
        getAllReview()
    }, [0])


    const getAllReview = () => {
        dispatch(getRatings())
        .then((response) => {
            if (response?.payload?.results) {
                setReviews(response.payload.results)
            }
            if (response?.payload?.rating) {
                setRating(response.payload.rating)
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Customer's Rating</h3>
                </Col>

                <div className="d-grid gap-4 mt-4">
                    <Rating 
                        showCounter={true}
                        size="34px"
                    />
                    <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Control
                                type="search"
                                placeholder="Search by ID"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <div className="filter-button gap-1">
                                <span>Filter by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <div className="filter-button gap-1">
                                <span>Sort by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Row>

                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Reviews</th>
                                        <th>Ratings</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reviews?.length ? (
                                            reviews.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            {/* <img src={item.avatar} alt="avatar" width="30" className="rounded-circle"/> */}
                                                            <strong>
                                                                {item.full_name}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td className="w-50 p-4">
                                                        {item.reviews}
                                                    </td>
                                                    <td>
                                                        <RatingStar 
                                                            count={item.rating_value} 
                                                            size="16px"
                                                        />
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            {item.date}
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        ) 
                                        :
                                        <tr>
                                            <td colSpan={4} className="text-center">No review(s) available</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {/* <Paginator/> */}
                </div>
            </Container>
        </Layout>
    );
}

export default Ratings;