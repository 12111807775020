import { Row, Col, Container, Card, Form, Button, Table, Badge, Dropdown, Modal, Spinner } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../components/table/Paginator";
import { useEffect, useState } from "react";
import receipt_avatar from '../assets/images/receipt.svg';
import DrewsModal from "../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getTransactionHistory, inflowAnalytics } from "../redux/slices/account.service";
import formatCurrency from "../utils/currencyFormmter";
import Logo from "../components/Logo";
import dateFormatter from "../utils/dateFormatter";
import RecordFilter from "../components/RecordFilter";

const Transactions = () => {


    const dispatch = useDispatch()

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [activeTab, setActiveTab] = useState('all')
    const [month, setMonth] = useState(1);
    const [modalShow, setModalShow] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [receipt, setReceipt] = useState({})
    const [show_receipt, setShowReceipt] = useState(false)
    const [copiedText, setCopiedText] = useState('');
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    // const [page, setPage] = useState(1)
    const [inflow_analytics, setInflowData] = useState({})
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: ''
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadTransactions()
        loadInflowData()
    }, [0, dateFormatter, payload.page])

    const nextMonth = () => {
        if(month < 11) {
            setMonth((prev) => (prev === month.length ? prev : ++prev));
        }
    }

    const prevMonth = () => {
        if(month > 0 && month <= 11) {
            setMonth((prev) => (prev === month.length ? prev : --prev));
        }
    }

    const selectTransaction = (id) => {
        const transaction = transactions.filter(item => item.reference === id)[0]
        if(transaction.note == 'Electricity') {
            setReceipt(transaction);
            setShowReceipt(true)
        }
        else {
            setTransaction(transaction)
            setModalShow(true)
        }
    }

    const loadTransactions = () =>  {
        setLoading(true)
        dispatch(getTransactionHistory(payload))
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
                setTimeout(() => {
                    filterTransactions(response.payload.results, 'all')
                }, 1000);
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const loadInflowData = () =>  {
        dispatch(inflowAnalytics())
        .then((response) => {
            // console.log("INFLOW", response)
            setInflowData(response.payload)
        })
    }

    const filterTransactions  = (payload = transactions, value) => {

        if(value.toLowerCase().includes('voucher')) {
            setActiveTab('vouchers')
        }
        else if(value.toLowerCase().includes('invoice')) {
            setActiveTab('invoices')
        }
        else {
            setActiveTab(value)
        }

         
       const data = value === 'failed' ? 
            payload?.filter(trans => trans.status !== 'success') :
            (value !== 'all' ? payload?.filter(trans => trans.transaction_type === value) : payload) 
        setResults(data)
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
          .then(() => {
            setCopiedText(copyText);
        })
    };

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }

    const handleSearch = () => {
        loadTransactions()
    }

 
    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Transactions History</h3>
                    <div className="months-toggle shadow-sm">
                        <Button 
                            type="button" 
                            variant="light" 
                            size="sm"
                            onClick={prevMonth}
                        >
                            <i className="lni lni-chevron-left"></i>
                        </Button>
                        <span>{months[month]}</span>
                        <Button 
                            type="button" 
                            variant="light" 
                            size="sm"
                            onClick={nextMonth}
                        >
                            <i className="lni lni-chevron-right"></i>
                        </Button>
                    </div>
                </Col>
                {
                    loading ? (
                        <div className="page-loader">
                            <Spinner size="lg" variant="primary"/>
                        </div>
                    ) : null
                }
                <div className="mt-4">
                    <Row className="data-card mb-3">
                        <Col md={4} sm={12} xs={12} lg={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--primary">
                                <Card.Body className="d-flex align-items-center gap-3 p-4">
                                    <div className="icon ">
                                        <i className="lni lni-notepad" style={{fontSize: "25px"}}></i>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.total_transactions || '0'}</h5>
                                        <p className="m-0">All Transactions</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} xs={12} lg={3} className="mb-2">
                            <Card className="border-0 shadow-sm">
                                <Card.Body className="d-flex align-items-center gap-3 p-4">
                                    <div className="icon bg-light">
                                        <img src={receipt_avatar} alt="receipt" width={25}/>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.total_invoices || '0'}</h5>
                                        <p className="m-0">All Invoices</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} xs={12} lg={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--secondary">
                                <Card.Body className="d-flex align-items-center gap-3 p-4">
                                    <div className="icon">
                                        <FontAwesomeIcon icon="tag" style={{fontSize: "25px"}}/>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.total_vouchers || '0'}</h5>
                                        <p className="m-0">All Vouchers</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} xs={12} lg={3} className="mb-2">
                            <Card className="border-0 shadow-sm bg--secondary">
                                <Card.Body className="d-flex align-items-center gap-3 p-4">
                                    <div className="icon">
                                        <FontAwesomeIcon icon="credit-card" style={{fontSize: "25px"}}/>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{formatCurrency(inflow_analytics?.total_inflow || '0').with_currency}</h5>
                                        <p className="m-0">Inflow Payments</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                        params={[
                            {label: 'Status', value: 'status'},
                            {label: 'Reference', value: 'reference'},
                            {label: 'Type', value: 'type'},
                            {label: 'Date', value: 'date'},
                        ]}
                    />
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <Table size="sm" className="table-color table-stripedd">
                                <thead>
                                    <tr>
                                        <th>Details</th>
                                        <th>Order</th>
                                        <th>Amount</th>
                                        <th>Balance Before</th>
                                        <th>Balance After</th>
                                        {/* <th>Date Created</th> */}
                                        <th>Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results?.length ? (
                                            results?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                            <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                <i className="lni lni-arrow-top-right"></i>
                                                            </small>
                                                            <strong>
                                                                <p className="m-0">{item.note}</p>  
                                                                <small>{dateFormatter(item.date)}</small>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td> {item.order}  </td>
                                                    <td>{formatCurrency(item.amount, item.currency).with_currency}</td>
                                                    <td>{formatCurrency(item.amount_before, item.currency).with_currency} </td>
                                                    <td>{formatCurrency(item.amount_after, item.currency).with_currency} </td>
                                                    {/* <td>
                                                        {item.date}
                                                    </td> */}
                                                    <td>
                                                        <p className="m-0 d-flex align-items-center gap-2">
                                                            <FontAwesomeIcon icon="fa fa-money-check"/>
                                                            {item.transaction_type}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.status === 'success' ? 'badge-active' : 
                                                                (item.status === 'failed' ? 'badge-failed' : 
                                                                (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.status === 'success' ? "#008000" : 
                                                                        (item.status === 'failed' ? "#EB5757" : 
                                                                        (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.status}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.reference)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="text-danger d-flex align-items-center gap-1"
                                                                        onClick={ () => selectTransaction(item.reference) }
                                                                    >
                                                                        <i className="lni lni-question-circle"></i> Report Transaction
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        : 
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                    badge_text={transaction.type}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Transaction ID</small>
                                                {transaction.reference} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Note</small>
                                                {transaction?.note || 'Nil'} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Beneficiary Account</small>
                                                {transaction?.balance || 'Nil'}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Status</small>
                                                <span 
                                                className={transaction.status === 'success' ? 'text-success' : 
                                                (transaction.status === 'failed' ? 'text-danger' : 
                                                (transaction.status === 'used' ? 'text-dark' : 'text-warning'))}
                                                >
                                                    {transaction.status}
                                                </span> 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Amount</small>
                                            {formatCurrency(transaction.amount).with_currency}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Fee</small>
                                            {formatCurrency(transaction.fee).with_currency}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Transaction Date</small>
                                            {transaction.date}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <Modal 
                    show={show_receipt} 
                    fullscreen={true} 
                    onHide={() => setShowReceipt(false)}
                    dialogClassName="receipt-screen-modal"
                    backdrop="static"
                >
                    <Modal.Header>
                        <button className="btn-plain">
                            <FontAwesomeIcon icon="chevron-left" onClick={ () => setShowReceipt(false) }/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="invoice px-3 py-0 border border-0">
                            <div className="border-bottom border-bottom-1 d-flex align-items-center justify-content-between pb-2 py-2">
                                <Logo variant="dark"/>
                                <div>
                                    <h5 className="mb-0 text-right">Transaction Receipt</h5>
                                    <p className="text-muted text-sm m-0 text-right">Generated: {receipt.date != null ? dateFormatter(receipt.date) : ''}</p>
                                </div>
                            </div>
                            <div className="table-responsive mb-3">
                                <table className="table border-0">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Amount
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {formatCurrency(receipt.amount).with_currency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Type
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.bill_type}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Service Provider
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.provider || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Date
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt.date != null ? dateFormatter(receipt.date) : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Meter No:
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.meterNo || 'Nill'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Token
                                            </td>
                                            <td className="text-primary border-0 text-right copy-text">
                                                {receipt?.tokenCode || 'Nill'} 
                                                <button className="btn-plain">
                                                    <FontAwesomeIcon icon={"copy"} className="text-success" onClick={ ()  => handleCopy(receipt?.tokenCode || 'Nill') }/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction ID
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.order}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Transactions;