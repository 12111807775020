import ServiceHelper from "./service.helper";

export default class SupportService {

    static createTicket(payload) {
        return ServiceHelper.handlePostRequest('/support_tickets/', payload)
    }

    static replyTicket(payload) {
        return ServiceHelper.handlePostRequest('/ticket_response/', payload)
    }

    static getTickets() {
        return ServiceHelper.handleGetRequest('/support_tickets/?action=tickets')
    }

    static getTicketReplies(id) {
        return ServiceHelper.handleGetRequest(`/support_tickets/?action=replies&ticket_id=${id}`)
    }

    static markResolved(id) {
        return ServiceHelper.handlePatchRequest(`/ticket_response/`, {ticket_id: id})
    }
}