import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Signup from './pages/auth/Signup';
import ProfileSetup from './pages/customer/ProfileSetup';
import Reports from './pages/Reports';
import Transactions from './pages/Transactions';
import Tickets from './pages/ticket';
import TicketView from './pages/ticket/View';
import Invoices from './pages/invoice';
import Paycode from './pages/paycode';
import Voucher from './pages/voucher';
import BillsPayment from './pages/billspayment';
import Wallets from './pages/wallets';
import P2pPayment from './pages/P2pPayment';
import Subscriptions from './pages/subscription';
import Plans from './pages/subscription/Plans';
import Rewards from './pages/reward';
import Customers from './pages/customer';
import Ratings from './pages/rating';
import BusinessRequest from './pages/business';
import BusinessRequestForm from './pages/business/BusinessRequestForm';
import Messaging from './pages/messaging';
import Settings from './pages/settings';
import Payments from './pages/Payments';
import Managers from './pages/members';
import Sms from './pages/messaging/Sms';
import UniversalPaycode from './pages/paycode/UniversalPaycode';
import ManagerLogin from './pages/members/auth/Login';
import ManagerDashboard from './pages/members/dashboard';
import PaycodeVouchers from './pages/members/dashboard/Vouchers';
import MemberTansactions from './pages/members/dashboard/Transactions';
import Notifications from './pages/members/dashboard/Notifications';
import ChangePassword from './pages/members/dashboard/ChangePassword';
import ForgotPasswordMember from './pages/members/auth/ForgotPassword';
import ResetPasswordMember from './pages/members/auth/ResetPassword';
import QrPayment from './pages/QrPayment';
import Announcement from './pages/Announcement';
import { useSelector } from 'react-redux';
import FasePay from './pages/fasepay';
import LoanRequest from './pages/LoanRequest';
import Inventory from './pages/inventory';
import StockRequest from './pages/inventory/StockRequest';
import StockDetails from './pages/inventory/StockDetails';
import TaxCalculator from './pages/TaxCalculator';
import LoyaltyPaycode from './pages/paycode/LoyaltyPaycode';
import StockInventory from './pages/members/inventory/StockInventory';
import StockItemDetails from './pages/members/inventory/StockItemDetails';
import StockItemRequest from './pages/members/inventory/StockItemRequest';
import TaxCalculatorx from './pages/members/TaxCalculatorx';

const privateRoutes = [
  { path: '/', role: '', authRequired: false, exact: true, component: Login },
  { path: '/dashboard', role: "admin", authRequired: true, exact: true, adminRoute: Home },
  { path: '/transactions', role: "admin", authRequired: true, exact: true, adminRoute: Transactions },
  { path: '/invoices', role: "admin", authRequired: true, exact: true, adminRoute: Invoices },
  { path: '/paycodes', role: "admin", authRequired: true, exact: true, adminRoute: Paycode },
  { path: '/fasepay', role: "admin", authRequired: true, exact: true, adminRoute: FasePay },
  { path: '/tickets', role: "admin", authRequired: true, exact: true, adminRoute: Tickets },
  { path: '/tickets/:id', role: "admin", authRequired: true, exact: true, adminRoute: TicketView },
  { path: '/reports', role: "admin", authRequired: true, exact: true, adminRoute: Reports },
  { path: '/vouchers', role: "admin", authRequired: true, exact: true, adminRoute: Voucher },
  { path: '/bills-payment', role: "admin", authRequired: true, exact: true, adminRoute: BillsPayment },
  { path: '/wallets', role: "admin", authRequired: true, exact: true, adminRoute: Wallets },
  { path: '/p2p-payment', role: "admin", authRequired: true, exact: true, adminRoute: P2pPayment },
  { path: '/subscriptions', role: "admin", authRequired: true, exact: true, adminRoute: Plans },
  { path: '/subscriptions/plans', role: "admin", authRequired: true, exact: true, adminRoute: Subscriptions },
  { path: '/rewards', role: "admin", authRequired: true, exact: true, adminRoute: Rewards },
  { path: '/customers', role: "admin", authRequired: true, exact: true, adminRoute: Customers },
  { path: '/managers', role: "admin", authRequired: true, exact: true, adminRoute: Managers },
  { path: '/ratings', role: "admin", authRequired: true, exact: true, adminRoute: Ratings },
  { path: '/business-request', role: "admin", authRequired: true, exact: true, adminRoute: BusinessRequest },
  { path: '/business-request/form', role: "admin", authRequired: true, exact: true, adminRoute: BusinessRequestForm },
  { path: '/messaging', role: "admin", authRequired: true, exact: true, adminRoute: Messaging },
  { path: '/messaging/sms', role: "admin", authRequired: true, exact: true, adminRoute: Sms },
  { path: '/settings', role: "admin", authRequired: true, exact: true, adminRoute: Settings },
  { path: '/announcements', role: "admin", authRequired: true, exact: true, adminRoute: Announcement },
  { path: '/loan-request', role: "admin", authRequired: true, exact: true, adminRoute: LoanRequest },
  { path: '/inventory', role: "admin", authRequired: true, exact: true, adminRoute: Inventory },
  { path: '/stock-request', role: "admin", authRequired: true, exact: true, adminRoute: StockRequest },
  { path: '/inventory/stock-details/:id', role: "admin", authRequired: true, exact: true, adminRoute: StockDetails },
  { path: '/tax-calculator', role: "admin", authRequired: true, exact: true, adminRoute: TaxCalculator },
  { path: '/loyalty-voucher', role: "admin", authRequired: true, exact: true, adminRoute: LoyaltyPaycode },
  
  { path: '/members/bills-payment', role: "manager", authRequired: true, exact: true, component: BillsPayment },
  { path: '/members/fasepay', role: "manager", authRequired: true, exact: true, component: FasePay },
  { path: '/members/p2p-payment', role: "manager", authRequired: true, exact: true, component: P2pPayment },
  { path: '/members/announcements', role: "manager", authRequired: true, exact: true, component: Announcement },
  { path: '/members/messaging', role: "manager", authRequired: true, exact: true, component: Messaging },
  { path: '/members/messaging/sms', role: "manager", authRequired: true, exact: true, component: Sms },
  { path: '/members/invoices', role: "manager", authRequired: true, exact: true, component: Invoices },
  { path: '/members/customers', role: "manager", authRequired: true, exact: true, component: Customers },
  { path: '/members/dashboard', role: "manager", authRequired: true, exact: true, component: ManagerDashboard },
  { path: '/members/paycodes', role: "manager", authRequired: true, exact: true, component: PaycodeVouchers },
  { path: '/members/transactions', role: "manager", authRequired: true, exact: true, component: MemberTansactions },
  { path: '/members/notifications', role: "manager", authRequired: true, exact: true, component: Notifications },
  { path: '/members/change-password', role: "manager", authRequired: true, exact: true, component: ChangePassword },
  { path: '/members/reset-password', role: "", authRequired: false, exact: true, component: ResetPasswordMember },
  { path: '/members/forgot-password', role: "", authRequired: false, exact: true, component: ForgotPasswordMember },
  { path: '/members/login', role: "", authRequired: false, exact: true, component: ManagerLogin },

  { path: '/members/inventory', role: "manager", authRequired: true, exact: true, component: StockInventory },
  { path: '/members/stock-request', role: "manager", authRequired: true, exact: true, component: StockItemRequest },
  { path: '/members/inventory/stock-details/:id', role: "manager", authRequired: true, exact: true, component: StockItemDetails },
  { path: '/members/tax-calculator', role: "manager", authRequired: true, exact: true, component: TaxCalculatorx },
  { path: '/members/loyalty-voucher', role: "manager", authRequired: true, exact: true, component: LoyaltyPaycode },

  { path: '/payments', role: "", authRequired: false, exact: true, component: Payments },
  { path: '/payments/qr-pay/:id', role: "", authRequired: false, exact: true, component: QrPayment },
  { path: '/paycodes/generate', role: "", authRequired: false, exact: true, component: UniversalPaycode },
  { path: '/invoices/payment', role: "", authRequired: false, exact: true, component: Payments },
  { path: '/profile-setup', role: "", authRequired: false, exact: true, component: ProfileSetup },
  { path: '/signup', role: "", authRequired: false, exact: true, component: Signup },
  { path: '/reset-password', role: "", authRequired: false, exact: true, component: ResetPassword },
  { path: '/forgot-password', role: "", authRequired: false, exact: true, component: ForgotPassword },
];

function App() {

  const { isLoggedIn, isManager } = useSelector(state => state.auth);

  return (
    <Routes>
      {
        privateRoutes.map(route => (
          <Route 
            path={route.path}
            key={route.path}
            exact={route.exact}
            element={
              !isLoggedIn && route.authRequired ? <Navigate to={'/'}/> :
              !route.authRequired ? <route.component/> : 
              isLoggedIn && route.role === 'admin' && !isManager ? <route.adminRoute/> :
              isLoggedIn && route.role === 'manager' && isManager ? <route.component/> :
              <Navigate to={'/'}  replace/>
              
              // <route.component/>
              // isLoggedIn && !isManager && route.role === "admin" ? <route.component /> : 
              // (isLoggedIn && !isManager && route.role === "manager" ? <route.components/> : <Navigate to="/"/>)
            }
          />
        ))
      }

      {/* {
        privateRoutes.map(route => 
          <Route 
            path={route.path}
            key={route.path}
            element = {
              isLoggedIn && route.role === "admin" && !isManager ? <route.component /> : 
              (isLoggedIn && isManager && route.role === "manager" ? <route.component/> : <Navigate to="/" replace/>)
            }
          />  
        )
      } */}
    </Routes>
  );
}

export default App;
library.add(fab, fas, far)