import { Row, Col, Container, Card, Button, InputGroup, Form, Spinner, Nav } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { changeAccountPassword, logout } from "../../../redux/slices/managers.slice";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { user, isLoggedIn } = useSelector((state) => state.auth)

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/managers/login")
        }
    }, [0])


    const [payload, setPayload] = useState({})
    const [loading, setLoading] = useState(false)
    const [show_password, setShowPassword] = useState(false)

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({ ...payload, [name]:value })
    }

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const changePassword = () => {
        setLoading(true)
        dispatch(changeAccountPassword(payload))
        .then((res) => {
            if(res.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: 'Password changed successfully. Please login to continue.'
                })
                dispatch(logout())
                navigate("/managers/login")
            }
            else if(res.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const actionConfirmation = () => {
        if(!payload.current_password && !payload.new_password && !payload.password_confirmation) return;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#443792',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed'
        })
        .then((result) => {
            if (result.isConfirmed) {
                changePassword()
            }
        })
    }

    return (
        <Layout>
            <>
                <Row className="justify-content-center">
                    <Col md={7}>
                        <Container fluid className="mt-5">
                            <Card>
                                <Card.Header className="bg-transparent">
                                    <h3 className="text-center">Hi { user?.first_name+' '+user?.last_name }</h3>
                                </Card.Header>
                                <Card.Body className="px-4 py-5 table-responsive-lg">
                                    <p className="text-center text-primary font-bold">Please use a password you can easily remember but strong enough.</p>
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                <Form.Label>Current Password</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type={show_password ? "text" : "password"}
                                                        placeholder="Password"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        name="current_password"
                                                        onChange={handleFormInput}
                                                        maxLength={12}
                                                        minLength={12}
                                                    />
                                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                <Form.Label>New Password</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type={show_password ? "text" : "password"}
                                                        placeholder="Password"
                                                        required
                                                        name="new_password"
                                                        onChange={handleFormInput}
                                                        maxLength={12}
                                                        minLength={12}
                                                    />
                                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-2">
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type={show_password ? "text" : "password"}
                                                        placeholder="Password"
                                                        required
                                                        name="password_confirmation"
                                                        onChange={handleFormInput}
                                                        maxLength={12}
                                                        minLength={12}
                                                    />
                                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} ms={12} lg={12} className="mb-2 mt-2">
                                                <Button 
                                                    type="button" 
                                                    variant="primary" 
                                                    className="form-control"
                                                    onClick={() => actionConfirmation()}
                                                    size="lg"
                                                >
                                                    Change Changes <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default ChangePassword;