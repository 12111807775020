import { Row, Col, Container, Card, Button, Form, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useState } from "react";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { chargeBusinessRegistration } from "../../redux/slices/account.service";
import { useDispatch, useSelector } from "react-redux";


const BusinessRequest = () => {

    const { business } = useSelector((state) => state.user)


    const business_types = [
        {name: 'Sole Proprietorship', slug: 'sole_proprietorship', price: 17500}, 
        {name: 'Limited Liability Company', slug: 'limited_liability', price: 50000}
    ];

    const business_categories = [
        {name: 'Business Name', slug: 'business_name', price: 50000}, 
        {name: 'Company Name', slug: 'company_name', price: 120000}
    ];

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [business_type, setBusinessType] = useState(business_types[0])
    const [category, setCategory] = useState(business_categories[0])
    const [load_iframe, setLoadIframe] = useState(false)

    const submitRequest = () => {
        Swal.fire({
            icon: 'warning',
            text: `You will be charged ${formatCurrency(business_type.price).with_currency} for ${business_type.name} registration`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Yes, Proceed",
            cancelButtonText: "No, Cancel"
        })
        .then((result) => {
            setLoading(true)
            if(result.isConfirmed) {
                setLoading(true)
                dispatch(chargeBusinessRegistration({
                    amount: business_type.price, 
                    registration_type: business_type.slug === 'sole_proprietorship' ? 'business_name' : 'company_name', 
                    merchant_id: business?.merchant_id
                }))
                .then((response) => {
                    if(response.payload?.status === true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.payload?.message
                        })
                        setLoadIframe(true)
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.payload?.message || 'Could not charge your wallet. Please try again'
                        })
                        setLoadIframe(false)
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-5 px-4 mb-4">

                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="border-0 shadow-sm rounded-16">
                            <Card.Body className="py-4 pe-3 ps-3">
                                <div className="text-center">
                                    <h4>Business Incorporation Request</h4>
                                </div>
                                <div className="p-3 rounded pt-5">
                                    <Form>
                                        {
                                            !load_iframe ? (
                                                <Row>
                                                    <Col md={12} className="mb-4">
                                                        <h5 className="m-0 text-center">Select Business Type</h5>
                                                    </Col> 
                                                    <Form.Group as={Col} md={12} sm={12} className="mb-3 d-block text-center">
                                                        {
                                                            business_types.map((business, index) => (
                                                                <Form.Label 
                                                                    htmlFor={"businessType"+index} 
                                                                    key={index} as={Button} variant="outline-primary"
                                                                    className={`mx-2 rounded-20  ${business_type.slug === business.slug ? 'active' : '' }`}
                                                                    onClick={ () => setBusinessType(business) }
                                                                >
                                                                    <input 
                                                                        type="hidden" 
                                                                        name="business_type" 
                                                                        className="ms-2" 
                                                                        id={"businessType"+index} value={business.slug} checked={business.slug === business_type.slug}
                                                                    /> {business.name}
                                                                </Form.Label>
                                                            ))
                                                        }
                                                    </Form.Group>
                                                    {/* <Form.Group as={Col} md={12} sm={12} className="mb-3 d-block text-center">
                                                        {
                                                            business_categories.map((biz, index) => (
                                                                <Form.Label 
                                                                    htmlFor={"businessType"+index} 
                                                                    key={index} as={Button} variant="outline-primary"
                                                                    className={`mx-2 rounded-20 btn-sm  ${category.slug === biz.slug ? 'active' : '' }`}
                                                                    onClick={ () => setCategory(biz) }
                                                                >
                                                                    <input 
                                                                        type="hidden" 
                                                                        name="category" 
                                                                        className="ms-2" 
                                                                        id={"businessType"+index} value={biz.slug} checked={biz.slug === category.slug}
                                                                    /> {biz.name}
                                                                </Form.Label>
                                                            ))
                                                        }
                                                    </Form.Group> */}
                                                    <Col md={12} className="text-center">
                                                        <h2 className="font-bold">{formatCurrency(business_type.price).with_currency}</h2>
                                                    </Col> 
                                                    {/* {
                                                        proceed ? (
                                                            <Row className="justify-content-center">
                                                                <Col md={5} sm={12} className="mb-3 card border-0">
                                                                    <Form.Group className="card-body text-center">
                                                                        <p>4-Digit Transaction PIN</p>
                                                                        <div className="otp-boxes w-100">
                                                                            {
                                                                                Array.from({length: 4}, (item, indexx) => (
                                                                                    <input 
                                                                                        type="text" 
                                                                                        maxLength={1} 
                                                                                        minLength={1} 
                                                                                        className="box" 
                                                                                        key={indexx}
                                                                                        onChange={ (e) => handleOtpInputs(indexx, e) }
                                                                                        ref={ inputRefs[indexx] }
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <small className="mt-1 text-muted">Enter wallet PIN</small>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={12} sm={12}>
                                                                    <Form.Group className="mt-4">
                                                                        <Button 
                                                                            type="button"
                                                                            variant="primary" 
                                                                            className="form-control" 
                                                                            size="lg"
                                                                            // onClick={() => setLoadIframe(true)}
                                                                            onClick={sendWithrawalRequest}
                                                                            disabled={Object.keys(errors).length > 0 || pinValue.join("").length < 4}
                                                                        >
                                                                            Pay Now <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        ) : 
                                                    } */}
                                                    <Form.Group className="mt-4">
                                                        <Button 
                                                            type="button"
                                                            variant="primary" 
                                                            className="form-control" 
                                                            size="lg"
                                                            onClick={submitRequest}
                                                        >
                                                            Proceed <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                        </Button>
                                                    </Form.Group>
                                                </Row>
                                            ) :
                                            <Row>
                                                <Col md={12}>
                                                    <iframe src="https://wallx-bn.vercel.app/" width={"100%"} allowFullScreen height={500}></iframe>
                                                </Col>
                                            </Row>
                                        }
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default BusinessRequest;