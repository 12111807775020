import InventoryServiceHelper from './service.inventory.helper';

export default class InventoryService {

    static API_URI = 'admin';

    static login(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sign-in`, payload);
    }

    static signup(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/register`, payload);
    }

    static createAdmin(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-admin`, payload);
    }

    static addStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-product`, payload);
    }

    static getAllStock(param) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-products?page=${param.page}`);
    }

    static getStockById(id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/product/${id}`);
    }

    static updateStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}`, payload);
    }

    static deleteStock(id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/product/${id}`);
    }

    static importStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/import-product`, payload);
    }

    static filterStock(params) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/filter-product?page=${params.page}&sku=${params.sku}&name=${params.name}`);
    }

    static uploadProductImage(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/update-product-image/${payload.id}`, payload);
    }

    static scanBarcode(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}/barcode`, payload);
    }

    static stockAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/get-analytics`);
    }

    static restockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product-restock`, payload);
    }

    static stockRequests() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/restock`);
    }

    static manageStockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/restock-management`, payload);
    }

    static createVendor(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-vendor`, payload);
    }

    static getVendors() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-vendors`);
    }

    static getVendorById(vendor_id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }

    static updateVendor(payload) {
        return InventoryServiceHelper.handlePutRequest(`${this.API_URI}/vendor/${payload.vendor_id}`, payload);
    }

    static deleteVendor(vendor_id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }
    
    static createOrganisation(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-organisation/`, payload);
    }
    
    static wallxOnaboading(payload) {
        return InventoryServiceHelper.handlePostRequest('/wallx/onboarding', payload);
    }

}