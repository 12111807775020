import { Row, Col, Container, Button, Breadcrumb, Card, Table } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import WithdrawalForm from "../../components/WIthdrawal";
import AlertModal from "../../components/modal/AlertModal";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const Subscriptions = () => {

    const results = [
        {
            id: 1,
            reference: '68ysdghsuds',
            amount: '₦56,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'Paycode',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Itodo',
            status: 'success',
            plan: 'Premium',
            period: 2,
        },
        {
            id: 2,
            reference: '68ysdghsuds',
            amount: '₦56,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'P2P Payment',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Itodo',
            status: 'failed',
            plan: 'Premium',
            period: 2,
        },
        {
            id: 3,
            reference: '68ysdghsuds',
            amount: '₦56,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'Voucher',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Andrew',
            status: 'success',
            plan: 'Premium',
            period: 2,
        },
        {
            id: 4,
            reference: 'gdjsjds',
            amount: '₦16,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'Paycode',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Onuh ANdrew',
            status: 'used',
            plan: 'Standard',
            period: 2,
        },
        {
            id: 5,
            reference: '68ysdghsuds',
            amount: '₦56,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'Voucher',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Itodo',
            status: 'success',
            plan: 'Premium',
            period: 3,
        },
        {
            id: 6,
            reference: '68ysdghsuds',
            amount: '₦60,000',
            balance: '₦24,000',
            date: '24/06/22',
            type: 'Paycode',
            description: 'Paycode Payment made from KingStore ',
            token: '5869696',
            name: 'Peter Itodo',
            status: 'success',
            plan: 'Premium',
            period: 5,
        },
    ];

    const [withdrawal, setWithdrawal] = useState(false)
    const [success, setSuccessRespone] = useState(false)

    const manageWithdrawal = () => {
        setWithdrawal(false)
    }

    return (
        <Layout>
            <Container className="mt-3 px-4 mb-4">
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Wallet</Breadcrumb.Item>
                    <Breadcrumb.Item active>My subscription</Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                    <Col md="12">
                        <h5>Manage my Subscription</h5>
                    </Col>
                    <Col md="6">
                        <div className="rounded-16 bg-white p-4 subscriptions">
                            <p>My active plan</p>
                            <ul>
                                <li>
                                    <span>Payment date</span>
                                    <span>August 23, 2023</span>
                                </li>
                                <li>
                                    <span>Due date</span>
                                    <span>September 23,2023</span>
                                </li>
                                <li>
                                    <span>Plan type</span>
                                    <span>Standard business</span>
                                </li>
                                <li>
                                    <span>Subscription type</span>
                                    <span>Monthly</span>
                                </li>
                                <li>
                                    <span>Total payment</span>
                                    <span>N25,000</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="rounded-16 bg-white p-4 subscriptions">
                            <h5>Next payment</h5>
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                                <div className="d-flex gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                        <path d="M24 16V24L30 21M40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24Z" stroke="#B2B1FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div>
                                        <h6>Due date</h6>
                                        <p>September 23, 2022</p>
                                    </div>
                                </div>
                                <div style={{ width: 120, height: 120 }} className="mx-auto">
                                    <CircularProgressbarWithChildren
                                     value={20}
                                     styles={buildStyles({
                                        pathColor: '#E39218'
                                     })}
                                    >
                                        <div style={{ fontSize: 13 }}>
                                            12 days left
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between gap-2">
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="bg-white border border-1 form-control text-primary" 
                                    size="lg"
                                >
                                    Cancel plan
                                </Button>
                                <Button type="button" variant="primary" className="form-control" size="lg">Renew</Button>
                            </div>
                        </div>
                    </Col>
                    <Col md="12 mt-3 mb-3">
                        <h5>Subscription History</h5>
                        <Card className="shadow-sm border-0 rounded-16">
                            <Card.Body className="ps-4 pe-4">
                                <Table size="sm" className="table-color">
                                    <thead>
                                        <tr>
                                            <th>Subscription Date</th>
                                            <th>Amount</th>
                                            <th>Period (Months)</th>
                                            <th>Plan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        results.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.date}
                                                    </td>
                                                    <td>
                                                        {item.amount}
                                                    </td>
                                                    <td>
                                                        {item.period}
                                                    </td>
                                                    <td className="text-secondary">
                                                        {item.plan}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <DrewsModal
                    show={withdrawal}
                    onHide={() => setWithdrawal(false)}
                    size="md"
                    dismissable={true}
                    title="Withdrawal Request"
                >
                    <WithdrawalForm 
                        buttonText="Withdraw"
                        warning={true}
                        onClick={ manageWithdrawal }
                    />
                </DrewsModal>

                <AlertModal
                    title="Successful!"
                    text="Your account has been credited with ₦25000.00"
                    buttonText="Cancel"
                    onHide={() => setSuccessRespone(false)}
                    onClick={() => setSuccessRespone(false)}
                    show={success}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM47.42 23.42L27.42 43.42C27.2341 43.6075 27.0129 43.7562 26.7692 43.8578C26.5254 43.9593 26.264 44.0116 26 44.0116C25.736 44.0116 25.4746 43.9593 25.2309 43.8578C24.9871 43.7562 24.7659 43.6075 24.58 43.42L14.58 33.42C14.3935 33.2335 14.2456 33.0121 14.1447 32.7685C14.0438 32.5249 13.9918 32.2637 13.9918 32C13.9918 31.4674 14.2034 30.9566 14.58 30.58C14.9566 30.2034 15.4674 29.9918 16 29.9918C16.5326 29.9918 17.0434 30.2034 17.42 30.58L26 39.18L44.58 20.58C44.9566 20.2034 45.4674 19.9918 46 19.9918C46.5326 19.9918 47.0434 20.2034 47.42 20.58C47.7966 20.9566 48.0082 21.4674 48.0082 22C48.0082 22.5326 47.7966 23.0434 47.42 23.42Z" fill="#B2B1FF"/>
                    </svg>
                    
                </AlertModal>
            </Container>
        </Layout>
    );
}

export default Subscriptions;