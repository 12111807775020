import { Row, Col, Container, Card, Button, Form } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import WithdrawalForm from "../../components/WIthdrawal";
import AlertModal from "../../components/modal/AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const BusinessRequestForm = () => {

    const [withdrawal, setWithdrawal] = useState(false)
    const [success, setSuccessRespone] = useState(false)
    const [payload, setPayload] = useState({})

    const navigate = useNavigate()

    const manageWithdrawal = () => {
        setWithdrawal(false)
    }

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({...payload, [name]:value})
    }

    const goBack = () => {
        navigate('/business-request')
    }

    return (
        <Layout>
            <Container className="mt-5 px-4 mb-4">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="border-0 shadow-sm rounded-16">
                            <Card.Body className="py-4 pe-3 ps-3">
                                <div className="text-center">
                                    <h4>Fill your Details</h4>
                                </div>
                                <div className="p-3 rounded pt-5">
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-2">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="e.g. Onuh"
                                                    required
                                                    onChange={handleFormInput}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-2">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="e.g. Peter"
                                                    required
                                                    onChange={handleFormInput}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-2">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="e.g. example@me.com"
                                                    required
                                                    onChange={handleFormInput}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-2">
                                                <Form.Label>Phone number</Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    name="phone_number"
                                                    placeholder="e.g. 080123456789"
                                                    required
                                                    onChange={handleFormInput}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>BVN</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bvn"
                                                    placeholder="e.g. 20039867541"
                                                    required
                                                    minLength={11}
                                                    maxLength={11}
                                                    onChange={handleFormInput}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-2">
                                                <Form.Label>Means of Identity</Form.Label>
                                                <Form.Select onChange={handleFormInput} className="form-control">
                                                    <option value="">Select an option</option>
                                                    <option value="NIN">NIN</option>
                                                    <option value="PVC">PVC</option>
                                                    <option value="Driver's license">Driver's License</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-2">
                                                <Form.Label className="mb-0 text-sm">Means of identity</Form.Label>
                                                <label htmlFor="means_of_id" className="custom-file-select form-control form-control-lg p-3">
                                                <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload a document
                                                    <input
                                                        type="file"
                                                        name="means_of_id_file"
                                                        onChange={handleFormInput}
                                                        accept="image/*"
                                                        size="lg"
                                                        id="means_of_id"
                                                    />
                                                </label>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" className="mt-4">
                                                <Button 
                                                    type="button" 
                                                    variant="primary" 
                                                    size="lg" 
                                                    className="form-control"
                                                    onClick={goBack}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <DrewsModal
                    show={withdrawal}
                    onHide={() => setWithdrawal(false)}
                    size="md"
                    dismissable={true}
                    title="Withdrawal Request"
                >
                    <WithdrawalForm 
                        buttonText="Withdraw"
                        warning={true}
                        onClick={ manageWithdrawal }
                    />
                </DrewsModal>

                <AlertModal
                    title="Successful!"
                    text="Your account has been credited with ₦25000.00"
                    buttonText="Cancel"
                    onHide={() => setSuccessRespone(false)}
                    onClick={() => setSuccessRespone(false)}
                    show={success}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM47.42 23.42L27.42 43.42C27.2341 43.6075 27.0129 43.7562 26.7692 43.8578C26.5254 43.9593 26.264 44.0116 26 44.0116C25.736 44.0116 25.4746 43.9593 25.2309 43.8578C24.9871 43.7562 24.7659 43.6075 24.58 43.42L14.58 33.42C14.3935 33.2335 14.2456 33.0121 14.1447 32.7685C14.0438 32.5249 13.9918 32.2637 13.9918 32C13.9918 31.4674 14.2034 30.9566 14.58 30.58C14.9566 30.2034 15.4674 29.9918 16 29.9918C16.5326 29.9918 17.0434 30.2034 17.42 30.58L26 39.18L44.58 20.58C44.9566 20.2034 45.4674 19.9918 46 19.9918C46.5326 19.9918 47.0434 20.2034 47.42 20.58C47.7966 20.9566 48.0082 21.4674 48.0082 22C48.0082 22.5326 47.7966 23.0434 47.42 23.42Z" fill="#B2B1FF"/>
                    </svg>
                    
                </AlertModal>
            </Container>
        </Layout>
    );
}

export default BusinessRequestForm;