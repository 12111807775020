import { Col, Row, Card, Container, Button, Modal, Form, FormGroup, Collapse, Spinner } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import link from '../assets/images/link.svg';
import voucher from '../assets/images/voucher.svg';
import arrows from '../assets/images/arrows.svg';
import bank from '../assets/images/gtb.svg';
import wema from '../assets/images/wema.svg';
import sterling from '../assets/images/sterling.svg';
import monniepoint from '../assets/images/sterling.svg';
import yellowdot from '../assets/images/yellowdot.svg';
import Rating from "../components/rating/Rating";
import { useState, useEffect, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import { checkEligibility, fundDeposit, getBusiness, getWallets, withdrawalRequest } from "../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createVirtualAccount, getAccounts, getAnalytics, getTransferCharges } from "../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../utils/currencyFormmter";
import { PaystackButton } from "react-paystack";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  import { Bar } from "react-chartjs-2";
import HmoPerk from "../components/hmo/HmoPerk";
import toast from "react-hot-toast";
// import axios from "axios";
import { getProfile } from "../redux/slices/auth";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  
  
  const Home = () => {

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const week_labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const hourly_labels = ['00:00:00', '08:00:00', '10:00:00', '12:00:00', '14:00:00', '16:00:00', '18:00:00', '20:00:00', '22:00:00', '23:00:00'];

    const initialState = {
        isWalletFunding: false, 
        isWithdrawal: false,
        payload: { amount: null },
        loading: false,
        showForm: false,
        pinValue: ['', '', '', ''],
        wallet: {},
        show_balance: false,
        fee: 0.00,
        errors: {},
        analytics: [],
        greeting: null,
        inflowData: [],
        outflowData: [],
        labels: labels,
        system_info: '',
        profile: {}
    }

    const [isWalletFunding, setWalletFunding] = useState(false)
    const [isWithdrawal, setWithdrawal] = useState(false)
    const [payload, setPayload] = useState({amount: null})
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [pinValue, setpinValues] = useState(['', '', '', ''])
    const [wallet, setWallet] = useState({})
    const [show_balance, setShowBalance] = useState(false)
    const [fee, setFee] = useState(0.00)
    const [errors, setErrors] = useState({})
    const [hmo_form, setHmoForm] = useState(false)
    const [copiedText, setCopiedText] = useState()
    const [referral_link, setReferralLink] = useState('')
    const [params, setParams] = useState({type: 'month'})
    const [is_profile_verified, setProfileModal] = useState(true)
    const [state, setState] = useState(initialState)
    const [system_info, setSystemInfo] = useState('')
    const [accounts, setAccounts] = useState([])
    const [charges, setSystemCharges] = useState(0)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { user } = useSelector((state) => state.auth)
    const { message } = useSelector((state) => state.message)
    const { business } = useSelector((state) => state.user)

    const banks = {
        'wema-bank': wema,
        'sterling-bank': sterling,
        'gt-bank': bank,
        'moniepoint-bank': monniepoint
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: true
            },
        },
        scales: {
            x: {
                grid: { display: false }
            },
            y: {
                grid: { display: true }
            },
        }
    };
        
    

    const data = {
        labels: state.labels,
        datasets: [
            {
                label: 'Inflow',
                data: state.inflowData,
                borderColor: 'rgb(68, 55, 144)',
                backgroundColor: 'rgb(68, 55, 144)',
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Outlow',
                data: state.outflowData,
                borderColor: 'rgb(100, 97, 255)',
                backgroundColor: 'rgb(100, 97, 255)',
                fill: false,
                tension: 0.4,
            },
        ],
    }

    const componentProps = {
        email: user?.email,
        amount: payload.fund_amount ? (parseFloat(payload.fund_amount) * 100) : 0,
        metadata: {
            phone: user?.phone,
        },
        publicKey: process.env.REACT_APP_PAYSTACK_PK,
        text: "Fund Wallet",
        onSuccess: (data) =>  {
            // console.log("Thanks for doing business with us! Come back soon!!", data)
            paymentVerification(data.trxref)
        },   
        // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
    }
    
    useEffect(() => {
        greetingCustomer()
        loadWallets()
        loadAppData()
        setReferralLink(window.location.origin+'/signup?ref='+business?.referral_code ?? 'Loading...')
        setParams({type: 'month'})
        getSystemCharges()
    }, [0])

    useEffect(() => {
        getAnalyticsData()
    }, [params])

    useEffect(() => {
        if(message?.status && message?.status === 'True') {
            Swal.fire({
                icon: 'success',
                text: message.message
            })
        }
        else if(message?.status && message?.status === 'False') {
            Swal.fire({
                icon: 'error',
                text: message.message
            })
        }
        // dispatch(clearMessage())
    },[message, dispatch])

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 2000000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

        if(name === 'amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 0) {
            calculateFees()
        }

    }

    const requestVirtualAccount = () => {
        if(!payload.account_type &&( !payload.term || payload.term !== 'agreed')) { return }

        setLoading(true)
        dispatch(createVirtualAccount(payload))
        // .then((e) => {
        //     console.log(e)
        // })
        .finally(() => setLoading(false))
    }

    const sendWithrawalRequest = () => {
        if (!payload.amount) {return}
        if(!pinValue.length || pinValue.join("") === '' || pinValue.join("").length < 4) {
            setLoading(false)
            return;
        }

        setLoading(true)
        dispatch(withdrawalRequest({amount: payload.amount, pin: pinValue.join("")}))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setWithdrawal(false)
                    loadWallets()
                })
            }
            else if(response.payload?.success === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    const loadAppData = () => {
        dispatch(getBusiness())
        getUserProfile()
        // getAnalyticsData()
        // loadKeys()
        // hmoEligibility()
        // setHmoForm(true)

        dispatch(getAccounts())
        .then((response) => {
            if(response.payload instanceof Array) {
                setAccounts(response.payload)
            }
        })

    }
 
    const getUserProfile = () => { 
        dispatch(getProfile())
        .then((response) => {
            let msg = '';
            let data = response.payload

            if(data && data.email && data.verified == false) {
                msg =  'Your account is not verified. Please contact support'
            } 
            else if(data && data.email && !data.store_created) {
                navigate('/profile-setup') 
            } 
            setState(({...state, profile: data}))
        })
    }

    const loadWallets = () => {
        dispatch(getWallets())
        .then((res) => { 
            if(res.payload?.length) {
                setWallet(res.payload[0])
            }
        })
    }

    const calculateFees = ()  => {
        let amount = parseFloat(payload.amount);
        const percentage = (amount / 100) * charges
        const fee = percentage < 2500 ? percentage : 2500 
        setFee(fee)
        return fee
    }

    const paymentVerification = (reference) => {
        setLoading(true)
        dispatch(fundDeposit({reference: reference}))
        .then((response) => {
            // console.log("FUNDING", response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    hmoEligibility()
                    setWalletFunding(false)
                    loadWallets()
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const getAnalyticsData = () => {
        dispatch(getAnalytics(params))
        .then((response) => {
            if(response.payload?.inflow) {
                const jsonData = response.payload
                setState((prevState)=> ({...prevState, 'analytics': jsonData}))
                generateChart(jsonData)
            }
        })
    }

    const generateChart = (jsonData) => {
        
        const inflowData = labels.map((item, index) => {
            let ft = jsonData.inflow.filter(i => i.month_literal === item)
            if(ft.length) {
                return ft[0].transaction_amount
            }
            return 0
        })
        
        const outflowData = labels.map((item, index) => {
            let ft = jsonData.outflow.filter(i => i.month_literal === item)
            if(ft.length) {
                return ft[0].transaction_amount
            }
            return 0
        })

       setState((prevState)=> ({...prevState, 'inflowData': inflowData}))
       setState((prevState)=> ({...prevState, 'outflowData': outflowData}))
        
    }

    const greetingCustomer = () => {
        const currentHour = new Date().getHours();
        let greetingTxt
        if (currentHour >= 5 && currentHour < 12) {
            greetingTxt = "Good morning";
          } else if (currentHour >= 12 && currentHour < 17) {
            greetingTxt = "Good afternoon";
          } else {
            greetingTxt = "Good evening";
        }
        setState((prevState)=> ({...prevState, 'greeting': greetingTxt}))
    }

    const hmoEligibility = () => {
        dispatch(checkEligibility())
        .then((response) => {
            if(response.payload.success === true) {
                setHmoForm(true)
            }
        })
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
        .then(() => {
            setCopiedText(copyText);
            toast.success(`Copied!`, {  
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,                            
              onClose: () => setCopiedText(false),
            });
        })
    };

    const handleShare = async (link, text) => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: text,
              text: 'Description of the shared link',
              url: link
            });
            console.log('Link shared successfully');
          } catch (error) {
            console.error('Error sharing link:', error);
          }
        } else {
          console.log('Web Share API is not supported in this browser');
        }
    };


    const handleAnalyticsFilter = (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value})
        
        if(value === 'week') {
            setState({...state, labels: week_labels})
        }
        else if(value === 'day') {
            setState({...state, labels: hourly_labels})
        }
        else {
            setState({...state, labels: labels})
        }
    }

    const getSystemCharges = () => {
        dispatch(getTransferCharges())
        .then(response => {
            if(response.payload !== 'undefined') {
                const data = response.payload
                if(data.length) {
                    const charge = data.find(item => item.name === 'withdrawal')
                    if(charge.value) {
                        setSystemCharges(charge.value)
                    }
                }
            }
        })
    }
    

    return (
        <Layout>
            <Container className="mt-5 ">
                <Row>
                    <Col md={7} lg={7} sm={12} className="d-gridd gap-3">
                        <Card className="bg-primary text-white border-0 shadow-sm mb-3">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <p>
                                        {state.greeting} 🌄 {business?.store_name || user?.first_name} <br />
                                        <small>Here are the updates since you last logged in.</small>
                                    </p>
                                    <p>
                                       <button 
                                            className="btn-plain text-white"
                                            onClick={ () => navigate('/transactions') }
                                        >
                                            Transaction history 
                                            <span> <i className="lni lni-arrow-right"></i> </span>
                                        </button>  
                                    </p>
                                </div>
                                <div className="balances mt-3 mb-3">
                                    <h3 className="m-0">
                                        { show_balance ?  formatCurrency(wallet?.amount).with_currency : "₦**********"} 
                                        <button 
                                            className="btn-plain text-white" 
                                            onClick={ () => setShowBalance(!show_balance) }
                                        > 
                                            <FontAwesomeIcon icon={show_balance ? "far fa-eye-slash" : "far fa-eye"} /> 
                                        </button>
                                    </h3>
                                    <p className="mb-0"> <small>Available balance</small></p>
                                </div>
                                <div className="border-0 bg-primary justify-content-between d-flex mt-4 gap-2">
                                    <Button 
                                        type="button" 
                                        size="lg" 
                                        variant="secondary" 
                                        className="bg-light text-primary form-control align-items-center d-flex justify-content-center gap-2 border-0"
                                        onClick={ () => setWalletFunding(true) }
                                    >
                                        <FontAwesomeIcon icon={"plus"} style={{fontSize: "20px"}}/>
                                        <span className="px-2">Top-up Wallet</span>
                                    </Button>
                                    <Button 
                                        type="button" 
                                        size="lg" 
                                        variant="warning" 
                                        className="bg-warning text-dark form-control align-items-center d-flex justify-content-center gap-2 border-0"
                                        onClick={() => setWithdrawal(true)} 
                                    >
                                        <i className="lni lni-printer" style={{ fontSize: "20px" }}></i>
                                        <span className="px-2">Withdraw</span>
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Body>
                                <h6 className="text-muted">QUICK ACTIONS</h6>
                                <Row className="mt-3 mb-3 justify-content-between">
                                    <Col md={4} as={'button'} className="text-center btn-plain" onClick={ () =>  navigate('/invoices') }>
                                        <div className="custom-bordered px-3 py-4">
                                            <img src={link} alt="link" className="mt-2" />
                                            <p className="text-muted mt-3">Create Payment Link</p>
                                        </div>
                                    </Col>
                                    <Col md={4} as={'button'} className="text-center btn-plain" onClick={ () =>  navigate('/paycodes') }>
                                        <div className="custom-bordered px-3 py-4">
                                            <img src={voucher} alt="voucher" className="mt-2" />
                                            <p className="text-muted mt-3">Generate eVouchers</p>
                                        </div>
                                    </Col>
                                    <Col md={4} as={'button'} className="text-center btn-plain" onClick={ () =>  navigate('/p2p-payment') }>
                                        <div className="custom-bordered px-3 py-4">
                                            <img src={arrows} alt="arrows" className="mt-2" />
                                            <p className="text-muted mt-3">Pay a Business</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border-0 shadow-sm">
                            <Card.Header className="bg-white border-0 d-flex justify-content-end">
                                <select className="form-control w-25" name="type" onChange={handleAnalyticsFilter}>
                                    <option value="day">Hourly</option>
                                    <option value="week">Weekly</option>
                                    <option value="month" selected>Monthly</option>
                                </select>
                                {
                                    params.type === 'day' ? (
                                        <input 
                                            type="date" 
                                            name="date" 
                                            className="form-control form-control-sm w-25 ms-2" 
                                            onChange={handleAnalyticsFilter}
                                        />
                                    ) :  null
                                }
                            </Card.Header>
                            <Card.Body className="h-100">
                                <Bar options={options} data={data} />
                            </Card.Body>
                        </Card>
                    </Col>
                    
                    <Col md={5} lg={5} sm={12}>
                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Header className="border-0 bg-white">
                                <p className="m-0 title">YOUR BUSINESS ACCOUNT</p>
                            </Card.Header>
                            <Card.Body>
                                {
                                    typeof(accounts) === 'object' && accounts?.length ? (
                                        <>
                                            {
                                                accounts && accounts?.map((account, index) => (
                                                    <div className="mb-2 d-flex align-items-center" key={index}>
                                                        <img 
                                                            src={banks[account.bank_slug] ?? 'https://via.placeholder.com/70'} 
                                                            alt={account.bank_slug} 
                                                            width={70} 
                                                            className="thumbnail-image rounded-circle border border-1" 
                                                        />
                                                        {/* <img 
                                                            src={account.bank_slug === 'wema-bank' ? wema : 
                                                            (account.bank_slug == 'sterling-bank' ? sterling : bank ) } 
                                                            alt={account.bank_slug} 
                                                            width={70} 
                                                            className="thumbnail-image rounded-circle border border-1" 
                                                        /> */}
                                                        <div className="w-75 px-4 divider pb-4">
                                                            <strong className="d-block">{account.account_number}</strong>
                                                            <span className="text-muted">{account.account_name}</span> 
                                                            <img src={yellowdot} alt="dot" className="ms-2 me-2" />
                                                            <span className="text-muted">{account.bank_name}</span>
                                                        </div>
                                                        <Button 
                                                            type="button" 
                                                            variant="light" 
                                                            className="bg-white text-primary border-0"
                                                            onClick={ ()  => handleCopy(`${account.account_name+" "+account.account_number+" - "+account.bank_name}`) }
                                                        >
                                                            <FontAwesomeIcon icon="far fa-copy" style={{fontSize: "20px"}}/>
                                                        </Button>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    ) : null
                                }
                                <Collapse in={showForm}>
                                    <div id="example-collapse-text">
                                        <Form>
                                            <Row>
                                                <FormGroup as={Col} md={12}>
                                                    <Form.Label className="text-primary">Select Preferred Bank</Form.Label>
                                                    <Form.Select className="form-control" name="account_type" onChange={handleInput}>
                                                        <option value="">--Select--</option>
                                                        <option value="gt">GTB</option>
                                                        <option value="wema">WEMA</option>
                                                        <option value="sterling">Sterling</option>
                                                        <option value="moniepoint-bank">Monnie Point MFB</option>
                                                    </Form.Select>
                                                </FormGroup>
                                                <FormGroup as={Col} md={12} className="mt-2">
                                                    <Form.Check
                                                        label="I agree to the Wallx Business Terms & Conditions and the company's privacy policy"
                                                        name="term"
                                                        className="text-primary"
                                                        value="agreed"
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Button 
                                                type="button" 
                                                variant="primary" 
                                                className="form-control mb-0 border-0 mt-2 mb-3"
                                                onClick={requestVirtualAccount}
                                            >
                                                Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                            </Button>
                                        </Form>
                                    </div>
                                </Collapse>
                                {
                                    typeof(accounts) === 'object' && (accounts?.length < 2) ? (
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            className="form-control bg-white text-primary align-items-center d-flex justify-content-center gap-2 mb-0 border-0"
                                            onClick={() => setShowForm(!showForm)}
                                            aria-controls="example-collapse-text"
                                        >
                                            <i className="lni lni-circle-plus"></i> <strong>Request Virtual Account</strong>
                                        </Button>
                                    ) : null
                                }
                            </Card.Body>
                        </Card>

                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Header className="border-0 bg-white">
                                <p className="m-0 title">REFER US & EARN</p>
                            </Card.Header>
                            <Card.Body>
                                <div className="custom-bordered d-flex align-items-center justify-content-between p-2 mb-3">
                                    <span>{referral_link}</span>
                                    <Button 
                                        type="button" 
                                        variant="light" 
                                        className="bg-white text-primary border-0" 
                                        onClick={ ()  => handleCopy(referral_link) }
                                    >
                                        <FontAwesomeIcon icon="far fa-copy" style={{fontSize: "20px"}}/>
                                    </Button>
                                </div>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="form-control bg-white text-primary align-items-center d-flex justify-content-center gap-2 mb-0 border-0"
                                >
                                    <button 
                                        type="button" 
                                        className="btn-plain text-primary"
                                        onClick={ () => handleShare(referral_link, "Referral") }
                                    >
                                        Share link &nbsp; <strong><i className="lni lni-arrow-top-right"></i></strong> 
                                    </button> 
                                </Button>
                            </Card.Body>
                        </Card>

                        <Card className="border-0 shadow-sm mb-3">
                            <Card.Header className="border-0 bg-white">
                                <p className="m-0 title">SCAN QR CODE</p>
                            </Card.Header>
                            <Card.Body>
                                <div className="bg-light qr-img p-4 text-center rounded-16">
                                    <QRCodeSVG 
                                        value={`${window.location.origin}/payments/qr-pay/${business?.merchant_id}`} 
                                        size={300}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <Rating 
                            showCounter={true} 
                        />
                    </Col>
                </Row>

                {/* Wallet funding modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={isWalletFunding}
                    onHide={() => setWalletFunding(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Fund your Wallet</Modal.Header>
                    <Modal.Body className="p-5">
                        <Row>
                            <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label className="mb-0 text-sm">Amount</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="₦0.00"
                                    name="fund_amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.fund_amount) > 0 ? formatCurrency(payload.fund_amount).formatted : ''}
                                />
                                {
                                    errors['fund_amount'] && 
                                    <Form.Text className="text-danger">{errors['fund_amount']}</Form.Text>
                                }
                            </Form.Group>
                        </Row>
                        <Form.Group className="mt-4">
                            <PaystackButton className="btn btn-primary form-control" {...componentProps} />
                        </Form.Group>
                    </Modal.Body>
                </Modal>

                {/* Wallet withdrawal modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={isWithdrawal}
                    onHide={() => setWithdrawal(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Withdrawal Request</Modal.Header>
                    <Modal.Body className="p-4">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Account Name</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Account Name"
                                        name="account_name"
                                        value={wallet?.account_name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Account Number</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Account Number"
                                        name="account_number"
                                        value={wallet?.account_number}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Bank</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        placeholder="Bank"
                                        name="bank_name"
                                        value={wallet?.bank_name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Amount(NGN)</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                    <p className="m-0 text-secondary">
                                        <small>Maximum Limit: {formatCurrency(2000000).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group as={Col} md={12} className="mb-3">
                                    <Form.Label className="mb-0 text-sm">Transaction Fee</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="₦0.00"
                                        name="total_amount"
                                        disabled
                                        value={fee}
                                    />
                                    <p className="m-0 text-secondary">
                                        <small>{charges}% fees on each withdrawal capped @ {formatCurrency(2500).with_currency}</small>
                                    </p>
                                </Form.Group>
                                <Form.Group>
                                    <p>4-Digit Transaction PIN</p>
                                    <div className="otp-boxes w-50">
                                        {
                                            Array.from({length: 4}, (item, indexx) => (
                                                <input 
                                                    type="text" 
                                                    maxLength={1} 
                                                    minLength={1} 
                                                    className="box" 
                                                    key={indexx}
                                                    onChange={ (e) => handleOtpInputs(indexx, e) }
                                                    ref={ inputRefs[indexx] }
                                                />
                                            ))
                                        }
                                    </div>
                                    <small className="mt-1 text-muted">Enter wallet PIN</small>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mt-4">
                                <Button 
                                    type="button"
                                    variant="primary" 
                                    className="form-control" 
                                    size="lg"
                                    onClick={sendWithrawalRequest}
                                    disabled={Object.keys(errors).length > 0 || pinValue.join("").length < 4}
                                >
                                    Withdraw <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>

                <HmoPerk show_form={ hmo_form } />

                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={!is_profile_verified}
                    onHide={() => setProfileModal(false)}
                    backdrop="static"
                >
                    <Modal.Header className="info-header">
                        <Modal.Title className="m-auto">Attention</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <Card className="border-0">
                            <Card.Body>
                                <div className="text-center">
                                    {
                                        !state.profile.payment_added ? (
                                            <h5>Complete your store setup by filling your payout bank details.</h5>
                                        ) :
                                        <h5>{system_info}</h5>
                                    }
                                </div>
                            </Card.Body>
                            <Card.Footer className="bg-transparent border-0">
                                {
                                    !state.profile.payment_added ? (
                                        <Button
                                            variant="primary"
                                            className="w-100"
                                            onClick={() => navigate('/profile-setup')}
                                        >
                                            Set Your Payment Method
                                        </Button>
                                    ) :
                                    <Button
                                        variant="primary"
                                        className="w-100"
                                        onClick={() => navigate('/profile-setup')}
                                    >
                                        Setup your store
                                    </Button>
                                }
                            </Card.Footer>
                        </Card>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Home;