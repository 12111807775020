import { useEffect, useState } from "react";
import { Modal, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { rateCustomer } from "../../redux/slices/users";
import { useDispatch } from "react-redux";

const CustomerRating = (props) => {

    const dispatch = useDispatch()

    const initialState = {
        show: false,
        loading: false,
        payload: {
            full_name: '',
            rate: '',
            review: '',
            mobile: '',
            email: '',
        },
        errors: {},
        show_form: props.show_form,
        merchant_id: props.merchant.merchant_id
    }

    const [state, setState] = useState(initialState)
    const [payload, setPayload] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setState((prevState)=> ({...prevState, 'show_form': props.show_form}))
    }, [props.show_form])

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState) => ({...prevState, [name]: value}))
        // setState((prevState) => ({ ...prevState, [state.payload[name]]: value }))
    }

    const handleValidation = (event) => {
        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
            // setState((prevState) => ({ ...prevState, ['errors']: state.errors}))
        }
    }

    const submitRating = () => {
        setState((prevState)=> ({...prevState, 'loading': true}))
        let request_payload = payload
        request_payload.merchant_id = props.merchant.merchant_id
        dispatch(rateCustomer(request_payload))
        .then((response) => {
            if(response.payload.success === true) {
                Swal.fire({
                    text: response.payload.message,
                    icon: "success"
                })
                .then(() => {
                    setState((prevState)=> ({...prevState, 'show_form': false}))
                })
            }
            else if(response.payload.success === 'False') {
                Swal.fire({
                    text: response.payload.message,
                    icon: "error"
                })
            }
        })
        .finally(() => setState((prevState)=> ({...prevState, 'loading': false})))
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={state.show_form}
            onHide={() => setState((prevState)=> ({...prevState, 'show_form': false}))}
            backdrop="static"
        >
            <Modal.Header closeButton className="font-bold">Rate { props.merchant.store_name }</Modal.Header>
            <Modal.Body className="px-4">
                <Form>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label className="mb-0">Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Full Name"
                                name="full_name"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                            />
                            {
                                errors['full_name'] && 
                                <Form.Text className="text-danger">{errors['full_name']}</Form.Text>
                            }
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label className="mb-0">Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                required
                                placeholder="Email Address"
                                name="email"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                            />
                            {
                                errors['email'] && 
                                <Form.Text className="text-danger">{errors['email']}</Form.Text>
                            }
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label className="mb-0">Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                required
                                placeholder="Phone Number"
                                name="mobile"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                            />
                            {
                                errors['mobile'] && 
                                <Form.Text className="text-danger">{errors['mobile']}</Form.Text>
                            }
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label className="mb-0">Rating</Form.Label>
                            <Form.Select name="rate" className="form-control" 
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                            >
                                <option value="" disabled hidden >Please select your rating..</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Good">Good</option>
                                <option value="Average">Average</option>
                                <option value="Poor">Poor</option>
                                <option value="Bad">Very Bad</option>
                            </Form.Select>
                            {
                                errors['rate'] && 
                                <Form.Text className="text-danger">{errors['rate']}</Form.Text>
                            }
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label className="mb-0">Review</Form.Label>
                            <textarea
                                required
                                placeholder="Review"
                                name="review"
                                className="form-control"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                            ></textarea>
                            {
                                errors['review'] && 
                                <Form.Text className="text-danger">{errors['review']}</Form.Text>
                            }
                        </Form.Group>
                        <Form.Group 
                            as={Col} 
                            md="12" 
                            className="mt-4 d-grid"
                        >
                            <Button 
                                variant="primary" 
                                size="lg" 
                                onClick={submitRating}
                                disabled={Object.keys(errors).length > 0}
                            >
                                Submit <Spinner animation={ state.loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default CustomerRating;