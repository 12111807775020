import { useState, useRef, useEffect } from "react";
import { Col, Container, Form, Row, Button, InputGroup, Spinner } from "react-bootstrap";
import Logo from "../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import AlertModal from "../../components/modal/AlertModal";
import mail_logo from "../../assets/images/landing_page/mail2.svg";
import { useDispatch, useSelector } from "react-redux";
import { signup, verification } from "../../redux/slices/auth";
import DrewsModal from '../../components/modal/DrewsModal'
import { clearMessage } from '../../redux/slices/message.js'
import AuthService from "../../services/auth.service";
import toast from "react-hot-toast";
import { getCountryList } from "../../redux/slices/account.service";
import Select from 'react-select';
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

const Signup = () => {

    const inputRefs = [useRef(null), useRef('peter'), useRef(null), useRef(null), useRef(null), useRef(null)];

    const [validated, setValidated] = useState(false);
    const [show_password, setShowPassword] = useState(false)
    // const [is_active, setIsActive] = useState(false)
    const [payload, setPayload] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const [otpValue, setOtpValues] = useState(['', '', '', '', '', ''])
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null)
    const [checked, setCheck] = useState(false)
    const [signupComplete, setSignupComplete] = useState(false)
    const [errors, setErrors] = useState({})
    const [countries, setCountries] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()


    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { message } = useSelector((state) => state.message)
    const { isOtpVerified, isAccountCreated, isLoggedIn, user } = useSelector((state) => state.auth)

    useEffect(() => {
        // if(message && (message.status === 'False' || message.success === 'False')) {
        //     setAlertMessage(message.message)
        //     setShowAlert(true)
        //     dispatch(clearMessage())
        // }

        if(isAccountCreated && !isOtpVerified) {
            // setModalShow(true)
        }
        else if(isOtpVerified && isAccountCreated && modalShow) {
            setModalShow(false)
            setSignupComplete(true)
        }
    }, [message, isLoggedIn, dispatch, navigate, isAccountCreated, isOtpVerified,])

    useEffect(() => {
        dispatch(getCountryList())
        .then((e) => {
            if(typeof(e.payload) === 'object') {
                const options =  e.payload?.map(country =>  { 
                   return {value: country.id, label: country.name, id: country.id} 
                })
                setCountries(options)
            }
        })
    }, [dispatch])

    const handleSubmit = () => {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      if(validated && pattern.test(payload.email)) {
        handleSignup()
      }

    };

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const handleOtpInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const otpValues = [...otpValue]
        otpValues[index] = value
        setOtpValues(otpValues)

        if (value !== '' && index < 5) {
            inputRefs[index + 1].current.focus()
        }
        
    }

    const handleValidation = (event) => {
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const login = () => {
        navigate('/')
    }

    const handleSignup  = () => {
        if(!payload.first_name  || !payload.last_name  || !payload.password  || !payload.email ) {
            toast.error('All fields are required!')
            return;
        }
        setLoading(true)
        dispatch(signup(payload))
        .then((e) => {
            if(e.payload?.success && e.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: e.payload?.message,
                })
                .then(() => {
                    setPayload({})
                    login()
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: e.payload?.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const handleCheckbox = () => {
        setPayload((prevState) => ({...prevState, terms: !checked}))
        setCheck(!checked)
    }

    const otpVerification = () => {
        setLoading(true)
        if(!otpValue.length || otpValue.join("") === '' || otpValue.join("").length < 6) {
            setLoading(false)
            return;
        }
        dispatch(verification({otp: otpValue.join(""), email: user.email || payload.email}))
        .finally(() => setLoading(false))
    } 

    const resendOtp = async () => {
        setLoading(true)
        try {
            const response = await AuthService.resendOtp({email: user.email || payload.email})
            // console.log("Success", response)
            toast.success(response.message, {
                duration: 3000,
                position: 'top-center'
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error.message, {
                duration: 3000,
                position: 'top-center'
            })
        }
    } 

    const navLogin = () => {
        setSignupComplete(false)
        navigate("/")
    }

    const countryStateSelection = (event) => {
        setPayload((prevState) => ({...prevState, country: event.id}))
        setPayload((prevState) => ({...prevState, country_name: event.label}))
    }

    const reloadPage = () => {
        window.location.reload()
    }

    
    return (
        <Row className="full-height">
            <Col md={6} className="px-5">
                <Container className="mt-3 text-center px-5">
                    <div>
                        <Logo variant="dark"/>
                    </div>
                    <h3 className="text-primary mt-2">Create Account</h3>
                    <p className="text-dark">
                        Sign up to business Suite today, and start transacting
                    </p>
                </Container>
                <Container className="px-5 mt-5">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    size="lg"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['first_name'] && 
                                    <Form.Text className="text-danger">{errors['first_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    size="lg"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['last_name'] && 
                                    <Form.Text className="text-danger">{errors['last_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    required={true}
                                    type="email"
                                    placeholder="Email"
                                    pattern=".+@gmail\.com"
                                    name="email"
                                    size="lg"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['email'] && 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Select 
                                    name="country" 
                                    onChange={countryStateSelection} 
                                    options={countries}
                                    selected={payload.country_name}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: "45px",
                                            backgroundColor: "#f2f2f2"
                                            }),
                                    }}
                                />
                                {
                                    errors['country'] && 
                                    <Form.Text className="text-danger">{errors['country']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type={show_password ? "text" : "password"}
                                        placeholder="Password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        size="lg"
                                        name="password"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                    />
                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                        </Button>
                                    </InputGroup.Text>
                                    {
                                        errors['password'] && 
                                        <Form.Text className="text-danger">{errors['password']}</Form.Text>
                                    }
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Referral Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Referral Code"
                                    name="referral_code"
                                    size="lg"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    defaultValue={searchParams.get('ref')}
                                />
                                {
                                    errors['referral_code'] && 
                                    <Form.Text className="text-danger">{errors['referral_code']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    required
                                    label="I accept the terms and conditions"
                                    feedback="You must agree before submitting."
                                    feedbackType="invalid"
                                    name="terms"
                                    value={ checked }
                                    onChange={handleCheckbox}
                                />
                            </Form.Group>
                        </Row>
                        <div className="d-grid gap-3">
                            <Button 
                                type="button" 
                                size="lg" 
                                disabled={Object.keys(errors).length > 0}
                                onClick={handleSignup}
                            >
                                Create Account  <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                            <Form.Group className="mb-3 text-center">
                              <span className="text-muted">Already own an account?</span>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    size="sm"
                                    className="bg-white border-0 text-primary font-bold"
                                    onClick={login}
                                >
                                    Login
                                </Button>
                            </Form.Group>
                        </div>
                    </Form>
                    <DrewsModal
                        show={modalShow}
                    >
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <div className="text-center">
                                    <img src={mail_logo} alt="logo" />
                                </div>
                                <div className="text-center mt-4">
                                    <h5>Verify your Mail</h5>
                                    <p className="pe-5 ps-5 text-sm">
                                        We have you sent a verification mail. Kindly check your mail to verify your mail.
                                    </p>
                                    <div className="mb-5 mt-3">
                                        <p>Enter OTP</p>
                                        <Form>
                                            <div className="otp-boxes w-75 mx-auto">
                                                {
                                                    Array.from({length: 6}, (item, indexx) => (
                                                        <input 
                                                            type="text" 
                                                            maxLength={1} 
                                                            minLength={1} 
                                                            className="box" 
                                                            key={indexx}
                                                            onChange={ (e) => handleOtpInputs(indexx, e) }
                                                            ref={ inputRefs[indexx] }
                                                        />
                                                    ))
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <Button 
                                                    type="button" 
                                                    className="form-control" 
                                                    variant="primary"
                                                    onClick={ otpVerification }
                                                >
                                                    Verify <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center text-primary">
                                        <p className="m-0">Didn't get a mail?</p>
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            size="sm"
                                            className="input-group-btn-bg border-0 text-primary font-bold"
                                            onClick={resendOtp}
                                        >
                                            Resend
                                        </Button>
                                    </div>
                            </div>
                            </Col>
                        </Row>
                    </DrewsModal>
                    <AlertModal
                        title="Error"
                        text={alertMessage}
                        show={showAlert}
                        onHide={ () => setShowAlert(false) }
                        onClick={ () => setShowAlert(false) }
                        buttonText="Ok"
                    >
                        <FontAwesomeIcon className="text-danger" icon="circle-exclamation" style={{ fontSize: "54px" }} />
                    </AlertModal>
                    <AlertModal
                        title="Success"
                        text={ payload.country_name === 'Nigeria' ? "Signup successful. Please procced to login." : "Signup successful. Thank you for chosing WallX Business."}
                        show={signupComplete}
                        onClick={ payload.country_name === 'Nigeria' ? navLogin : reloadPage }
                        buttonText={payload.country_name === 'Nigeria' ? "Proceed" : "Close"}
                    >
                        <FontAwesomeIcon className="text-success" icon="check-circle" style={{ fontSize: "54px" }} />
                    </AlertModal>
                </Container>
            </Col>
            <Col md={6} className="bg-primary p-0">
                <Container className="mt-5 mb-2 ">
                    <Col className="text-white text-center px-5">
                        <h2>Share , Spend with Ease</h2>
                        <p>
                            Join WallX to easily conduct socio-lifestyle transactions. Suitbale for both businesses and individuals
                        </p>
                    </Col>
                </Container>
                <div className="login-banner">
                    <img src={require('../../assets/images/landing_page/dashboard.png')} alt="dashboard" />
                </div>
            </Col>
        </Row>
    );
}


export default Signup;