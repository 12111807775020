
import { Form } from "react-bootstrap";
import { useRef, useState } from 'react';

const WithdrawalPin = ({ sendPinValue }) => {


    const [pinValue, setpinValues] = useState(['', '', '', ''])

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];


    const handleInputs = (index, event) => {
        
        const value = event.target.value

        if(isNaN(value)) return;

        const pinValues = [...pinValue]
        pinValues[index] = value
        setpinValues(pinValues)
        sendPinValue(pinValue.join(""))

        if (value !== '' && index < 3) {
            inputRefs[index + 1].current.focus()
        }        
    }

    return (
        <Form.Group>
            <p>4-Digit Transaction PIN</p>
            <div className="otp-boxes w-50">
                {
                    Array.from({length: 4}, (item, index) => (
                        <input 
                            type="password" 
                            maxLength={1} 
                            minLength={1} 
                            className="box" 
                            key={index}
                            onKeyUp={ (e) => handleInputs(index, e) }
                            ref={ inputRefs[index] }
                        />
                    ))
                }
            </div>
            {/* <small className="mt-1 text-muted">{propTypes.text}</small> */}
        </Form.Group>
    );
}

export default WithdrawalPin;